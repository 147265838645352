export class B2BTipoTransporte {
  name: string;
  label: string;


  constructor(name: string, label: string) {
    this.name = name;
    this.label = label;

  };

}
   
    
       
       
    
      
      
