import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
//import { GformsComponent } from './gforms/gforms.component'
//import { JesusComponent } from './jesus/jesus.component';


const routes: Routes = [
 // { path: '', redirectTo: '/dashboard' },
  //{ path: 'jesus', component: JesusComponent },
 // { path: 'georgina', component: GeorginaComponent },
//  { path: 'gforms', component: GformsComponent },
  { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy',onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
