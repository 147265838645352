import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { B2BCesta } from '../models/b2bCesta.model';
import { B2BTipoTransporte } from '../models/b2bTipoTransporte.model';

@Injectable({
  providedIn: 'root'
})

export class B2BCestaService {

  myAppUrl: string;
  myApiUrl: string;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(public http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.myAppUrl = baseUrl;
    this.myApiUrl = "api/b2bcesta";    
  }

  handleError(error: HttpErrorResponse) {
    console.log("Error Consumiendo B2BCestaService");
    return throwError(error.error);
  }
  GetCesta(codcli: string): Observable<B2BCesta[]> {

    const params = new HttpParams()
      .set('codcli', codcli);

    return this.http.get<B2BCesta[]>(this.myAppUrl + this.myApiUrl + "/cesta", { 'headers': this.httpOptions.headers, 'params': params })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }

  EnviarPedido(formData): Observable<B2BCesta>{
    return this.http.post<B2BCesta>(this.myAppUrl + this.myApiUrl + "/cesta/pedido", JSON.stringify(formData), this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  addCesta(formdata): Observable<B2BCesta> {
    return this.http.post<B2BCesta>(this.myAppUrl + this.myApiUrl + "/cesta/save", JSON.stringify(formdata), this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteCesta(formData) {

    return this.http.post(this.myAppUrl + this.myApiUrl + "/cesta/delete", formData, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteArticuloCesta(formData) {
    return this.http.post(this.myAppUrl + this.myApiUrl + "/articulo/delete", formData, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );

  }

  getTipoTransporte(): Observable<B2BTipoTransporte[]> {
    return this.http.get<B2BTipoTransporte[]>(this.myAppUrl + this.myApiUrl + "/tipotransporte", { 'headers': this.httpOptions.headers })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }
}
