<p-toast key="toast1"></p-toast>

<div class="container-fluid w-100 ml-5">
  <div class="grid">
    <div class="col-11 mt-4">
      <h1>Base de datos de imágenes</h1><span>Gestor ISB</span>
      <p-toolbar styleClass="p-mb-4 mt-4">
        <ng-template pTemplate="left">
          <button pButton pRipple label="Añadir imagen" icon="pi pi-plus" class="p-button-warning p-mr-2" (click)="openNew()"></button>
        </ng-template>
      </p-toolbar>
      <p-panel styleClass="bg-panel" header="Imágenes productos" [toggleable]="true">
        <p-table *ngIf="visible" #dt [columns]="properties" [lazy]="true" (onLazyLoad)="loadArticulosIMG($event)" [loading]="loading" [value]="articulosIMG"
                 styleClass="p-datatable-striped" [rows]="rows" (onPage)="changeRows($event)" [paginator]="true" [totalRecords]="totalRecords"
                 [globalFilterFields]="['codigo','nomart','marca','medidas','auxnum2','stock','precio','precio','descuento','neto']"
                 [rowHover]="true" dataKey="id"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
                 [rowsPerPageOptions]="[10,25,50,100]">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" class="search-filter" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Filtro..." />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let head of columns;  let j = index">
                <th class="text-center">{{head.label}}</th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-element let-columns="columns" let-i="rowIndex">
            <tr [ngClass]="{'row-color':i %2 == 0}">
              <ng-container *ngFor="let head of columns; let j = index">
                <td class="text-center" *ngIf="head.name == 'auxchr6'"> {{element[head.name]}} </td>
                <td class="text-center" *ngIf="head.name == 'url'">
                    <img [src]="transform(element[head.name])" width="100px" class="product-image">
                </td>
                <td class="text-center" *ngIf="head.name == 'edicion'">
                  <span>
                    <button pButton pRipple type="button" class="ml-1 p-button-info" (click)="mostrarArticuloIMG(element)">Editar</button>
                    <button pButton pRipple type="button" class="ml-1 p-button-danger" (click)="deleteArticuloIMG(element)">Eliminar</button>
                  </span>
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </p-panel>
    </div>
  </div>
</div>
  <p-dialog [(visible)]="articuloDialog" [style]="{width: '750px'}" header="Producto" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
      <div class="field">
        <!--pmessages-->
        <p-messages key="msg1"></p-messages>

        <div class="formgrid grid">
          <div *ngIf="!articuloIMG" class="col-12">
            <div class="col-6">
              <b>Código: </b>
            </div>
            <div class="col-6">
              <textarea [(ngModel)]="codigo" class="inputfield w-full" rows="1" pInputTextarea></textarea>
            </div>
          </div>
          <div class="col-12">
            <p-fileUpload name="demo[]" customUpload="true" (uploadHandler)="myUploader($event)" (onUpload)="onUpload($event)"
                          multiple="multiple" accept="image/*" maxFileSize="1000000">
              <ng-template pTemplate="content">
                <ul *ngIf="uploadedFiles.length">
                  <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                </ul>
              </ng-template>
            </p-fileUpload>
          </div>
        </div>
      </div>
    </ng-template>


    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cerrar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    </ng-template>
  </p-dialog>

  <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
