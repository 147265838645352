import { Component } from '@angular/core';
import { AuthenticationService } from '../core/services/authentication.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  constructor(public auth: AuthenticationService) { }
  ngOnInit(): void {
  }
}
