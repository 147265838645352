import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

//import { XRSKUser } from 'src/app/core/models/xrskuser.model';
import { B2BUser } from '../../../../core/models/b2buser.model';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, inputArt } from '../../../../core/services/data.service';
import { MatPaginator, MatPaginatorIntl  } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { B2BPedidosService } from '../../../../shared/services/b2bPedidos.service';
import { B2BPedidos } from '../../../../shared/models/b2bPedidos.model';
import { LiveAnnouncer } from '@angular/cdk/a11y';

import { DomSanitizer } from '@angular/platform-browser';
import { B2BPerfilService } from '../../../../shared/services/b2bPerfil.service';
import { B2BDirecciones } from '../../../../shared/models/b2bDirecciones.model';
import { DatePipe, formatDate } from '@angular/common';
import { B2BArticulosIMG } from '../../../../shared/models/b2bArticulosIMG.model';
import { FileUploadService } from '../../../../core/services/fileupload.service';
import { B2BOfertas } from '../../../../shared/models/b2bOfertas.model';
import { MessageService } from 'primeng/api';

//import { MessageService } from 'primeng/api';
export interface Banner {
  image?: string;
}


@Component({
  selector: 'app-b2bdashboard',
  templateUrl: './b2bdashboard.component.html',
  styleUrls: ['./b2bdashboard.component.scss'],
  providers: [MessageService]

})
export class B2BDashBoardComponent implements AfterViewInit, OnInit, OnDestroy {
  //Unsubcribe Handler
  private unsubscribe$ = new Subject<void>();


  displayedColumns: string[] = ['docser', 'refter', 'fecha', 'estcab','descargar'];
  sortColumns: string[] = ['Sort by docser', 'Sort by refter', 'Sort by fecha', 'Sort by estcab'];

  properties: any = [{ name: 'docser', label: "Nº Pedido" },
  { name: 'refter', label: "Pedido Cliente" },
  { name: 'fecha', label: "Fecha Pedido" },
  { name: 'estcab', label: "Estado" },
  { name: 'descargar', label: "Descargar" }];

  propertiesOfertas: any = [{ name: 'docser', label: "Nº Oferta" },
  { name: 'refter', label: "Referencia" },
  { name: 'fecha', label: "Fecha Oferta" },
  { name: 'estcab', label: "Estado" },
  { name: 'descargar', label: "Descargar" }];

  filterColumns = ["docser", "refter", "fecha", "estcab"];

  currentUser$: Observable<B2BUser>;
  public user: any = [];
  public collapsed00: boolean = false;
  public collapsed01: boolean = false;
  public collapsed02: boolean = false;
  @Input() currentUser: B2BUser;
  public url: any;
  banner: Banner[] = [{ image: 'banner1.jpg' },
                      { image: 'banner2.jpg' },
                      { image: 'banner3.jpg' }];

  pedidos: Array<B2BPedidos> = new Array<B2BPedidos>();
  pedidosFiltered: Array<B2BPedidos> = new Array<B2BPedidos>();
  ofertas: Array<B2BOfertas> = new Array<B2BOfertas>();
  ofertasFiltered: Array<B2BOfertas> = new Array<B2BOfertas>();

  dataSource = new MatTableDataSource<B2BPedidos>(this.pedidos);

  errorText: string = 'Hi ha un error!';
  searchText: string = '';
  // Progress Bar values
  estado = 'estado';
  mode = 'indeterminate'; 
  value = 50;   
  bufferValue = 75;
  public estados: any[] = [];
  public estadosOfe: any[] = [];
  public direccionesList: B2BDirecciones[] = [];
  public direccion: B2BDirecciones;

  public bannerIMG: B2BArticulosIMG[] = [];
  public isLoading = false;

  public rows: number;

  constructor(private data: DataService, public auth: AuthenticationService, private cdRef: ChangeDetectorRef, private route: ActivatedRoute,
    private pedidosService: B2BPedidosService, private readonly router: Router, private _liveAnnouncer: LiveAnnouncer, private domSanitizer: DomSanitizer,
    public _MatPaginatorIntl: MatPaginatorIntl, private el: ElementRef<HTMLElement>, private perfilService: B2BPerfilService, private datePipe: DatePipe,
    private fileService: FileUploadService, private messageService: MessageService  ) {


    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.GetDirecciones(this.currentUser.codigo);
    let value = JSON.parse(localStorage.getItem('rows'));
    if (value != undefined && value != null){
      this.rows = value;

    }else {
      this.rows = 50;
    }

  }

  ngOnInit(): void {

  
    this.estados = [
      { codigo: "E", descripcion: "Bloqueado" },
      { codigo: "V", descripcion: "Validado" },
      { codigo: "X", descripcion: "Extraído" },
      { codigo: "P", descripcion: "Parcial" },
      { codigo: "S", descripcion: "Servido" },
      { codigo: "H", descripcion: "Hostórico" }
    ];

    this.estadosOfe = [
      { codigo: "E", descripcion: "Bloqueado" },
      { codigo: "V", descripcion: "Validado" },
      { codigo: "C", descripcion: "Cursada" },
      { codigo: "H", descripcion: "Hostórico" }
    ];

   
    this.currentUser$ = this.auth.currentUserObs;
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.GetPedidos(this.user.codigo);
    this.GetOfertas(this.user.codigo);
    this.GetBannerIMG();

    //this.dataSource.data = this.pedidos;

    //this._MatPaginatorIntl.itemsPerPageLabel = 'Show entries';
    this.cdRef.detectChanges();

  }
  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }
  changeRows(event) {
    this.rows = event.rows;
    localStorage.setItem('rows', JSON.stringify(this.rows));

  }

  transform(base64Image) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }

  GetBannerIMG(): void {
    let formData = new FormData();

    if (this.currentUser.b2b_banner != null && this.currentUser.b2b_banner != undefined && this.currentUser.b2b_banner != "") {
      formData.append('tipo', this.currentUser.b2b_banner);
    } else {
      formData.append('tipo', 'NOR');

    }

    this.fileService.GetBannerIMGAll(formData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        articulos => {
          this.bannerIMG = articulos as unknown as B2BArticulosIMG[];
        },
        err => {

        }
      );
  }


  GetDirecciones(codcli: string): void {

    this.perfilService.GetDirecciones(codcli)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
      direcciones => {
        this.direccionesList = direcciones as unknown as B2BDirecciones[];
        this.direccion = this.direccionesList[0];
        localStorage.setItem('direcciones', JSON.stringify(this.direccionesList));

      },
      err => {

      }
    );
  }

  convertEstado(codigo: string): string {
    var convertedValue: string;

    convertedValue = this.estados.find(x => x.codigo == codigo).descripcion;

    return convertedValue;
  }

  convertEstadoOfe(codigo: string): string {
    var convertedValue: string;

    convertedValue = this.estadosOfe.find(x => x.codigo == codigo).descripcion;

    return convertedValue;
  }

  filterGlobal(value: any) {
    this.pedidosFiltered = new Array<B2BPedidos>();
    if (value.length > 0) {


      this.filterColumns.forEach(col => {
        this.pedidos.filter(ped => {
          if (ped[col] != null) {
            if (typeof ped[col] == "string") {

              if (col == "estcab") {
                if (!isNaN(Date.parse(ped[col]))) {

                  if (formatDate(ped[col], "dd-MM-yyyy", "es").toUpperCase().includes(value.toUpperCase())) {
                    this.pedidosFiltered.push(ped);
                  }

                } else {
                  if (this.convertEstado(ped[col]).toUpperCase().includes(value.toUpperCase())) {
                    this.pedidosFiltered.push(ped);

                  }

                }
              } else {
                if (!isNaN(Date.parse(ped[col]))) {

                  if (formatDate(ped[col], "dd-MM-yyyy", "es").toUpperCase().includes(value.toUpperCase())) {
                    this.pedidosFiltered.push(ped);
                  }

                } else {
                  if (ped[col].toUpperCase().includes(value.toUpperCase())) {
                    this.pedidosFiltered.push(ped);

                  }

                }
              }

            }
          }
        })

      })
    } else {
      this.pedidosFiltered = this.pedidos;
    }


  }

  filterGlobalOfertas(value: any) {
    this.ofertasFiltered = new Array<B2BPedidos>();
    if (value.length > 0) {


      this.filterColumns.forEach(col => {
        this.ofertas.filter(ofe => {
          if (ofe[col] != null) {
            if (typeof ofe[col] == "string") {

              if (col == "estcab") {
                if (!isNaN(Date.parse(ofe[col]))) {

                  if (formatDate(ofe[col], "dd-MM-yyyy", "es").toUpperCase().includes(value.toUpperCase())) {
                    this.ofertasFiltered.push(ofe);
                  }

                } else {
                  if (this.convertEstado(ofe[col]).toUpperCase().includes(value.toUpperCase())) {
                    this.ofertasFiltered.push(ofe);

                  }

                }
              } else {
                if (!isNaN(Date.parse(ofe[col]))) {

                  if (formatDate(ofe[col], "dd-MM-yyyy", "es").toUpperCase().includes(value.toUpperCase())) {
                    this.ofertasFiltered.push(ofe);
                  }

                } else {
                  if (ofe[col].toUpperCase().includes(value.toUpperCase())) {
                    this.ofertasFiltered.push(ofe);

                  }

                }
              }

            }
          }
        })

      })
    } else {
      this.ofertasFiltered = this.ofertas;
    }


  }

  generarPedido(docser: string) {
    this.isLoading = true;
    var oferta = new B2BOfertas();
    oferta.docser = docser;
    oferta.tercer = this.user.codigo;
    this.pedidosService.generarPedido(oferta)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      res => {

        this.GetOfertas(this.user.codigo);

        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Pedido Generado', life: 3000 });

      },
      err => {
        console.log('error downloading: ', err);
        this.messageService.add({ key: 'msg1', severity: 'error', summary: 'Error', detail: err });

        this.isLoading = false;
      }
    );

}



  descargarDocumento(tipo: string, docser: string) {
    this.isLoading = true;
    this.pedidosService.GetDocumento(tipo, docser)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
      blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        if (tipo == "csv") {
          a.download = 'pedido.csv';
        }
        if (tipo == "pdf") {
          a.download = 'pedido.pdf';
        }
        if (tipo == "xls") {
          a.download = 'pedido.xls';
        }
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.isLoading = false;
      },
      err => {
        console.log('error downloading: ', err);
        this.isLoading = false;
      }
    );
  
  }


  descargarDocumentoOfe(tipo: string, docser: string) {
    this.isLoading = true;
    this.pedidosService.GetDocumentoOfe(tipo, docser)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        blob => {
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          if (tipo == "csv") {
            a.download = 'pedido.csv';
          }
          if (tipo == "pdf") {
            a.download = 'pedido.pdf';
          }
          if (tipo == "xls") {
            a.download = 'pedido.xls';
          }
          a.click();
          URL.revokeObjectURL(objectUrl);
          this.isLoading = false;
        },
        err => {
          console.log('error downloading: ', err);
          this.isLoading = false;
        }
      );

  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  GetPedidos(codcli: string): void {

    this.pedidosService.GetPedidos(codcli)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
      pedidos => {


        this.pedidos = pedidos as unknown as B2BPedidos[];
        this.pedidos = this.pedidos.sort((a, b) => (a.docser > b.docser ? -1 : 1));
        this.pedidosFiltered = this.pedidos;
      },
      err => {
        var j = 1;
        
      }
    );
  }

  GetOfertas(codcli: string): void {

    this.pedidosService.GetOfertas(codcli)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ofertas => {


          this.ofertas = ofertas as unknown as B2BPedidos[];
          this.ofertas = this.ofertas.sort((a, b) => (a.docser > b.docser ? -1 : 1));
          this.ofertasFiltered = this.ofertas;
        },
        err => {
          var j = 1;

        }
      );
  }


  openCatalogo() {
    window.open('https://isb-iberica.com/catalogos/', '_blank');

  }

  buscar() {

    let artInput = new inputArt();
    artInput.codcli = this.user.codigo;
    artInput.sqlcond = this.searchText != null && this.searchText != undefined ? this.searchText : "";
    artInput.codfam = "";
    artInput.codcat = "";
    artInput.alto =  "";
    artInput.ancho = "";
    artInput.largo = "";

    if (artInput.codcli != undefined) {
      this.data.changeMessage(artInput);
    }
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/articulos']);
    //this.child.buscarClick();

  }


}
