
<div class="container-fluid w-100 ml-5">

  <div class="grid mt-4">
    <div class="col-8 col-offset-1">
      <p-carousel [value]="bannerIMG" styleClass="custom-carousel" [showNavigators]="false" [numVisible]="1" [numScroll]="1" [circular]="true" [autoplayInterval]="3000">
        <ng-template let-banner pTemplate="item">
          <div class="product-item">
            <div class="product-item-content">
              <div>
                <img [style]="{width: '100%'}" [src]="transform(banner.url)" />
              </div>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>

  <div class="grid mt-4">
    <div class="col-4 col-offset-1">
      <div class="card text-left text-white h-11rem">
        <div class="card-header">Búsqueda artículos</div>
        <div class="card-body">
          <mat-form-field appearance="outline" class="text-dark w-100">
            <input matInput [(ngModel)]="searchText" (keydown.enter)="buscar()">
            <button type="button" mat-icon-button matSuffix (click)="buscar()">
              <mat-icon class="text-dark">search</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card text-left text-white h-11rem">
        <div class="card-header">Catálogos</div>
        <div class="card-body">
          <a href="https://isb-iberica.com/catalogos/" target="_blank">
            <p class="card-text text-dark text-center">
              Acceda a toda la gama de catálogos&nbsp;&nbsp;
              <i class="far fa-file-alt fa-4x text-dark bg-white"></i>
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="grid mt-4 mb-4">
    <div class="col-8 col-offset-1">
      <p-messages key="msg1"></p-messages>
      <p-toast></p-toast>

      <p-panel styleClass="bg-panel" header="Ofertas" [toggleable]="true" iconPos="right">
        <p-table #dt [columns]="propertiesOfertas" [value]="ofertasFiltered" styleClass="p-datatable-striped" [paginator]="true" [globalFilterFields]="['docser', 'refter', 'fecha', 'estcab']"
                 [rowHover]="true" dataKey="id" [rows]="rows" (onPage)="changeRows($event)"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
                 [rowsPerPageOptions]="[10,25,50,100]">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" class="search-filter" (input)="filterGlobalOfertas($event.target.value)" placeholder="Filtro..." />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let head of columns">
                <th class="text-center" *ngIf="head.name != 'descargar' && head.name != 'docser'" [pSortableColumn]="head.name">{{head.label}} <p-sortIcon [field]="head.name"></p-sortIcon></th>
                <th class="text-center" *ngIf="head.name == 'docser'" [pSortableColumn]="head.name">{{head.label}} <p-sortIcon [field]="head.name"></p-sortIcon></th>

                <th class="text-center" *ngIf="head.name == 'descargar'">{{head.label}}</th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-element let-columns="columns" let-i="rowIndex">
            <tr [ngClass]="{'row-color':i %2 == 0}">
              <ng-container *ngFor="let head of columns">
                <td class="text-center" *ngIf="head.name == 'fecha'"> {{element[head.name]| date:'dd-MM-yyyy'}} </td>
                <td class="text-center" *ngIf="head.name != 'fecha' && head.name != 'estcab' && head.name != 'descargar'"> {{element[head.name]}} </td>

                <td class="text-center" *ngIf="head.name == 'estcab'"> {{convertEstadoOfe(element[head.name])}} </td>
                <td class="text-center" *ngIf="head.name == 'descargar'">
                  <button pButton type="button" label="PDF" class="p-button-outlined p-button-secondary mr-2" [loading]="isLoading" (click)="descargarDocumentoOfe('pdf',element.docser)"></button>
                  <button pButton type="button" label="Generar Pedido" class="p-button-outlined p-button-secondary mr-2" [loading]="isLoading" (click)="generarPedido(element.docser)"></button>

                </td>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </p-panel>
    </div>
  </div>

  <div class="grid mt-4 mb-4">
    <div class="col-8 col-offset-1">
      <p-panel styleClass="bg-panel" header="Pedidos últimos 15 días" [toggleable]="true"  iconPos="right">
        <p-table #dt [columns]="properties" [value]="pedidosFiltered" styleClass="p-datatable-striped" [paginator]="true" [globalFilterFields]="['docser', 'refter', 'fecha', 'estcab']"
                 [rowHover]="true" dataKey="id" [rows]="rows" (onPage)="changeRows($event)"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
                 [rowsPerPageOptions]="[10,25,50,100]">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" class="search-filter" (input)="filterGlobal($event.target.value)" placeholder="Filtro..." />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let head of columns">
                <th class="text-center" *ngIf="head.name != 'descargar' && head.name != 'docser'" [pSortableColumn]="head.name">{{head.label}} <p-sortIcon [field]="head.name"></p-sortIcon></th>
                <th class="text-center" *ngIf="head.name == 'docser'" [pSortableColumn]="head.name">{{head.label}} <p-sortIcon [field]="head.name"></p-sortIcon></th>

                <th class="text-center" *ngIf="head.name == 'descargar'">{{head.label}}</th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-element let-columns="columns" let-i="rowIndex">
            <tr [ngClass]="{'row-color':i %2 == 0}">
              <ng-container *ngFor="let head of columns">
                <td class="text-center" *ngIf="head.name == 'fecha'"> {{element[head.name]| date:'dd-MM-yyyy'}} </td>
                <td class="text-center" *ngIf="head.name != 'fecha' && head.name != 'estcab' && head.name != 'descargar'"> {{element[head.name]}} </td>

                <td class="text-center" *ngIf="head.name == 'estcab'"> {{convertEstado(element[head.name])}} </td>
                <td class="text-center" *ngIf="head.name == 'descargar'">
                  <button pButton type="button" label="PDF" class="p-button-outlined p-button-secondary mr-2" [loading]="isLoading" (click)="descargarDocumento('pdf',element.docser)"></button>
                  <button pButton type="button" label="CSV" class="p-button-outlined p-button-secondary mr-2" [loading]="isLoading" (click)="descargarDocumento('csv',element.docser)"></button>
                  <button pButton type="button" label="EXC" class="p-button-outlined p-button-secondary mr-2" [loading]="isLoading" (click)="descargarDocumento('xls',element.docser)"></button>
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </p-panel>
    </div>
  </div>
</div>


