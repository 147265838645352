
<div class="container-fluid w-100 ml-5">

  <div class="grid mt-4">
    <div class="col-8">
      <div class="card text-left text-white">
        <div class="card-header">Datos de Facturación</div>
        <div *ngIf="direccionesList" class="card-body text-dark w-100 text-justify">
          <div>{{direccionFact.empres}}</div>
          <div>{{direccionFact.direcc}}</div>
          <div>{{direccionFact.poblac}}</div>
          <div>{{direccionFact.nomprv}}</div>
          <div>{{direccionFact.codpos}}</div>
          <div>{{direccionFact.telef1}}</div>
          <div>{{direccionFact.codnac}}</div>
        </div>
      </div>
    </div>
    
    <div class="col-3" >
      <div class="card text-left text-white">
        <div class="card-header">Actualizar Contraseña</div>
        <div class="card-body text-dark w-100 text-justify">
          <form [formGroup]="form" (ngSubmit)="submit()">

            <div class="form-group mt-1">
              <span class="p-float-label">
                <p-password formControlName="password" [feedback]="true"  [toggleMask]="true" styleClass="p-password p-component p-inputwrapper p-input-icon-right"></p-password>
                <label for="password">Contraseña</label>
              </span>
              <div *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
                <div *ngIf="f.password.errors.required">Password is required.</div>
              </div>
            </div>

            <div class="form-group mt-5 mb-2">
              <span class="p-float-label">
                <p-password formControlName="confirm_password" [feedback]="true"  [toggleMask]="true" styleClass="p-password p-component p-inputwrapper p-input-icon-right"></p-password>
                <label  for="confirm_password">Confirmar Contraseña</label>
              </span>
              <div *ngIf="f.confirm_password.touched && f.confirm_password.invalid" class="alert alert-danger">
                <div *ngIf="f.confirm_password.errors.required">Password is required.</div>
                <div *ngIf="f.confirm_password.errors.confirmedValidator">Contraseña y confirmar Contraseña tienen que coincidir.</div>
              </div>
            </div>
            <p-messages class="mt-2" key="messagepwd"></p-messages>
            <p-button label="Enviar" class="mt-2 p-button-info" (onClick)="changePassword()" [loading]="isLoading" [disabled]="!form.valid"></p-button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="grid mt-4">
    <div class="col-8">
      <div *ngIf="direccionesList" class="card text-left text-white">
        <div class="card-header">Direcciones de envío</div>
        <div *ngFor="let dire of direccionesListEnv" class="card-body text-dark w-100 text-justify">
          <div>{{dire.empres}}</div>
          <div>{{dire.direcc}}</div>
          <div>{{dire.poblac}}</div>
          <div>{{dire.nomprv}}</div>
          <div>{{dire.codpos}}</div>
          <div>{{dire.telef1}}</div>
          <div>{{dire.codnac}}</div>
        </div>
      </div>
    </div>
  </div>


  <p-messages key="message"></p-messages>

  <div class="grid mt-4 mb-4" *ngIf="user.distribuidor == 0">
    <div class="col-8">
      <p-panel styleClass="bg-panel" header="Últimos Albaranes" [toggleable]="true" iconPos="right">
        <p-table #dtAlb [columns]="propertiesAlb" [lazy]="true" (onLazyLoad)="loadAlbaranes($event)" [loading]="loadingAlb" [value]="albaranes"
                 styleClass="p-datatable-striped" [rows]="rows" (onPage)="changeRows($event)" [paginator]="true" [totalRecords]="totalRecordsAlb"
                 [rowHover]="true" dataKey="idAlb"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
                 [rowsPerPageOptions]="[10,25,50,100]">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>          
                <input pInputText type="text"  class="search-filter" (input)="dtAlb.filterGlobal($event.target.value, 'contains')" placeholder="Filtro..." />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let head of columns">
                <th class="text-center" *ngIf="head.name != 'descargar'" [pSortableColumn]="head.name">{{head.label}} <p-sortIcon [field]="head.name"></p-sortIcon></th>
                <th class="text-center" *ngIf="head.name == 'descargar'">{{head.label}}</th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-element let-columns="columns" let-i="rowIndex">
            <tr [ngClass]="{'row-color':i %2 == 0}">
              <ng-container *ngFor="let head of columns">
                <td class="text-center" *ngIf="head.name == 'docser'"> {{element[head.name]}} </td>
                <td class="text-center" *ngIf="head.name == 'fecha'"> {{element[head.name]| date:'dd-MM-yyyy'}} </td>
                <td class="text-center" *ngIf="head.name != 'fecha' && head.name != 'descargar' && head.name != 'docser'"> {{element[head.name] | currency:'EUR':'symbol':'1.2-2'}} </td>
                <td class="text-center" *ngIf="head.name == 'descargar'">
                  <button pButton type="button" label="PDF" class="p-button-outlined p-button-secondary mr-2" [loading]="isLoadingAlb" (click)="descargarDocumentoAlbaran('pdf',element.docser)"></button>
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </p-panel>
    </div>
  </div>
  <p-messages key="messagef"></p-messages>

  <div class="grid mt-4 mb-4" *ngIf="user.distribuidor == 0">
    <div class="col-8">
      <p-panel styleClass="bg-panel" header="Últimas facturas" [toggleable]="true" iconPos="right">
        <p-table #dt [columns]="properties" [lazy]="true" (onLazyLoad)="loadFacturas($event)" [loading]="loading" [value]="facturas"
                 styleClass="p-datatable-striped" [rows]="rows" (onPage)="changeRows($event)" [paginator]="true" [totalRecords]="totalRecords"
                 [rowHover]="true" dataKey="id"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
                 [rowsPerPageOptions]="[10,25,50,100]">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" class="search-filter" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Filtro..." />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let head of columns">
                <th class="text-center" *ngIf="head.name != 'descargar'" [pSortableColumn]="head.name">{{head.label}} <p-sortIcon [field]="head.name"></p-sortIcon></th>
                <th class="text-center" *ngIf="head.name == 'descargar'">{{head.label}}</th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-element let-columns="columns" let-i="rowIndex">
            <tr [ngClass]="{'row-color':i %2 == 0}">
              <ng-container *ngFor="let head of columns">
                <td class="text-center" *ngIf="head.name == 'docser'"> {{element[head.name]}} </td>
                <td class="text-center" *ngIf="head.name == 'fecha'"> {{element[head.name]| date:'dd-MM-yyyy'}} </td>
                <td class="text-center" *ngIf="head.name != 'fecha' && head.name != 'descargar' && head.name != 'docser'"> {{element[head.name] | currency:'EUR':'symbol':'1.2-2'}} </td>
                <td class="text-center" *ngIf="head.name == 'descargar'">
                  <button pButton type="button" [loading]="isLoadingFac" label="PDF" class="p-button-outlined p-button-secondary mr-2" (click)="descargarDocumentoFactura('pdf',element.docser)"></button>
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </p-panel>
    </div>
  </div>
</div>


