import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, Input, Inject, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

//import { XRSKUser } from 'src/app/core/models/xrskuser.model';
import { B2BUser } from 'src/app/core/models/b2buser.model';
import { Observable, of, Subject, Subscription, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, inputArt, TotalCestaService } from '../../../../core/services/data.service';
import { ConfirmationService, LazyLoadEvent, Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { B2BCesta } from '../../../../shared/models/b2bCesta.model';
import { B2BCestaService } from '../../../../shared/services/b2bCesta.service';
import { B2BDirecciones } from '../../../../shared/models/b2bDirecciones.model';
import { B2BTipoTransporte } from '../../../../shared/models/b2bTipoTransporte.model';

@Component({
  selector: 'app-b2cesta',
  templateUrl: './b2cesta.component.html',
  styleUrls: ['./b2cesta.component.css'],
  providers: [MessageService]
})
export class B2BCestaComponent implements OnInit, OnDestroy, AfterViewInit {

  //Unsubcribe Handler
  private unsubscribe$ = new Subject<void>();

  //User Variables
  currentUser$: Observable<B2BUser>;
  public user: any = [];

  private lastTableLazyLoadEvent: LazyLoadEvent;


  //Data Variables
  articuloDialog: boolean;
  articulosCesta: B2BCesta[] = [];
  articuloCesta: B2BCesta;
  codigo: string;
  public direccionesList: B2BDirecciones[];
  public direccionesList$: Observable<B2BDirecciones[]>;
  public direccionesListEnv: B2BDirecciones[] = [];
  public direccionesListF: B2BDirecciones[] = [];
  public selectedDir: B2BDirecciones;
  public refter: any;
  public coment: any;
  current: Date;
  public totnet: number = 0;
  public desb2b: number = 0;
  public imptot: number = 0;
  public portes: string;
  //Table Variables
  datasource: B2BCesta[] = [];
  totalRecords: number;
  artInput = new inputArt();
  loading: boolean;
  isLoading: boolean = false;
  

  submitted: boolean;
  properties: any = [
    { name: 'codart', label: "Código" },
    { name: 'nomart', label: "Descripción" },
    { name: 'fecent', label: 'Fecha de envío' },
    { name: 'canofe', label: 'Cantidad' },
    { name: 'precio', label: 'Precio lista' },
    { name: 'descuento', label: 'Descuento' },
    { name: 'impnet', label: 'Importe Neto' },
    { name: 'imptot', label: 'Total Neto' },
    { name: 'gestion', label: 'Gestión' },
  ];

  public tipotransporte: B2BTipoTransporte[] = [];

  msgs1: string;



  selectedTipotransporte: B2BTipoTransporte = new B2BTipoTransporte("","");

  visible: boolean = true;
  public rows: number;
  minDate: Date;


  constructor(public auth: AuthenticationService, private route: ActivatedRoute, private cestaService: B2BCestaService,
    private messageService: MessageService, public domSanitizer: DomSanitizer, private router: Router,
    private confirmationService: ConfirmationService, private dataCesta: TotalCestaService) {




  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {

    let value = JSON.parse(localStorage.getItem('rows'));
    if (value != undefined && value != null) {
      this.rows = value;

    } else {
      this.rows = 50;
    }

    this.getTipoTransporteList();

    this.refter = JSON.parse(localStorage.getItem('refter'));
    this.coment = JSON.parse(localStorage.getItem('coment'));

    this.direccionesList = JSON.parse(localStorage.getItem('direcciones'));
    this.portes = this.direccionesList[0].portes;
    this.direccionesList$ = of(this.direccionesList);
    this.direccionesListF = this.direccionesList.filter(dire => dire.tipdir == "0");

    this.direccionesListEnv = this.direccionesList.filter(dire => dire.tipdir.includes("VE") == true || !isNaN(Number(dire.tipdir)) == true);
    this.direccionesListEnv.forEach(dire => dire.direcc = dire.empres + " | " + dire.direcc);
   

    this.currentUser$ = this.auth.currentUserObs;
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.GetCesta();
    this.loading = true;
    this.current = new Date();
    this.msgs1 = 'Pedido minimo de ' + this.user.minped + ' €';
    //this.msgs1.push({ severity: 'error', summary: 'Pedido minimo de ' + this.user.minped + ' €', closable:false });


    this.minDate = new Date();



  }

  changeRows(event) {
    this.rows = event.rows;
    localStorage.setItem('rows', JSON.stringify(this.rows));

  }

  changeComment(event) {
    this.coment = event;
    localStorage.setItem('coment', JSON.stringify(this.coment));

  }


  changeRefter(event) {
    this.refter = event;

    localStorage.setItem('refter', JSON.stringify(this.refter));

  }

  concatLabel(direcc: B2BDirecciones) {
    return direcc.empres + " | " + direcc.direcc;
  }

  getTipoTransporte(tiptra: string) {
    return this.tipotransporte.find(tipo => tipo.name == tiptra);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  mostrarArticuloIMG(cesta: B2BCesta) {
    this.articuloCesta = { ...cesta };
    this.articuloDialog = true;
  }
  enviarPedido() {

    var cesta = new B2BCesta();
    cesta.tercer = this.user.codigo;
    if (this.coment == null || this.coment == undefined) {
      this.coment = "";
    }
    cesta.coment = this.coment;
    cesta.direnv = this.selectedDir.tipdir;

    cesta.refter = this.refter;
    cesta.tiptra = this.selectedTipotransporte.name ?? null;
    cesta.portes = this.portes;
    this.isLoading = true;
    
    this.cestaService.EnviarPedido(cesta)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        res => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Pedido Enviado', life: 3000 });
          this.isLoading = false;
          this.dataCesta.changeMessage(0);
          localStorage.removeItem('refter');
          localStorage.removeItem('coment');

          this.router.navigate(['/dashboard']);

        }, err => {
          this.isLoading = false;

          this.messageService.add({ key: 'msg1', severity: 'error', summary: 'Error', detail: err });

        });

  }

  updateCesta(cesta: B2BCesta,event:any) {

    //this.loading = true;


    this.cestaService.addCesta(cesta)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Cesta Actualizada', life: 3000 });

        //this.GetCesta();
        // this.articuloDialog = false;
        this.datasource = this.ModElementoCesta(res);
        this.CalcularTotales(this.datasource);


       
        //this.articulosCesta = [...this.datasource];
        this.articuloCesta = null;
        this.codigo = null;

        this.loadCesta(this.lastTableLazyLoadEvent);

        //this.updateVisibility();
        //this.loading = false;

      });
  }

  deleteCesta() {
    //this.articuloIMG = null;
    //this.submitted = false;
    //this.edit = true;
    //this.articuloDialog = true;
    this.confirmationService.confirm({
      message: 'Estás seguro que quieres eliminar la cesta ?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {

        this.cestaService.deleteCesta(this.user)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
          res => {
            console.log("entra");

            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Cesta Eliminada', life: 3000 });

            //this.GetCesta();
              // this.articuloDialog = false;
              this.datasource = null;

              this.CalcularTotales(this.datasource);



              //this.articulosCesta = [...this.datasource];
              this.articuloCesta = null;
              this.codigo = null;

              this.loadCesta(this.lastTableLazyLoadEvent);

            //this.articulosCesta = [...this.datasource];
            this.articuloCesta = null;
            this.codigo = null;

            //this.updateVisibility();

          },
          err => {
            this.messageService.add({ key: 'msg1', severity: 'error', summary: 'Error', detail: "No es posible Borrar cesta" });

          }
        );

      }
    });
  }

  getTipoTransporteList() {

    this.cestaService.getTipoTransporte()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        tipotransporte => {
          this.tipotransporte = tipotransporte as unknown as B2BTipoTransporte[];

          this.tipotransporte.forEach(x => x.label = x.label ?? "\xa0")
          this.selectedTipotransporte.name = this.getTipoTransporte(this.direccionesListEnv[0].tiptra).name ?? "" ;
          this.selectedTipotransporte.label = this.getTipoTransporte(this.direccionesListEnv[0].tiptra).label ?? "\xa0";
          /*
          if (this.portes == "P") {
            this.selectedTipotransporte.name = "";
            this.selectedTipotransporte.label = "\xa0";
          } else {
            this.selectedTipotransporte.name = this.getTipoTransporte(this.direccionesListEnv[0].tiptra).name;
            this.selectedTipotransporte.label = this.getTipoTransporte(this.direccionesListEnv[0].tiptra).label;
        
          }*/

        },
        err => {
          this.messageService.add({ key: 'msg1', severity: 'error', summary: 'Error', detail: err });

        }
      );
  }

  CalcularTotales(cestaList: B2BCesta[]) {

    if (cestaList.length > 0) {
      this.totnet = 0;
      this.desb2b = 0;
      this.imptot = 0;

      cestaList.forEach(a => a.fecha = new Date(a.fecha));
      cestaList.forEach(a => a.fecent = new Date(a.fecent));
      this.datasource = cestaList as unknown as B2BCesta[];
      this.articulosCesta = cestaList as unknown as B2BCesta[];
      this.totalRecords = this.datasource.length;
      cestaList.forEach(a => this.totnet += a.imptot);



      this.desb2b += -cestaList[0].dtob2b;
      this.imptot = this.totnet - this.desb2b;


    } else {
      this.datasource = cestaList as unknown as B2BCesta[];
      this.articulosCesta = cestaList as unknown as B2BCesta[];
      this.totalRecords = this.datasource.length;
      this.totnet = 0;
      this.desb2b = 0;
      this.imptot = 0;
    }
  }

  ModElementoCesta(cesta: B2BCesta) {
    const cestaList = this.datasource.map(item => item.linid === cesta.linid ? cesta : item);

    return cestaList;
  }
 
  GetCesta(): void {

   // var mock = new B2BCesta(1,1, "90060075", "6025.0.13 ALW BOLA CON DOBLE RETEN	", new Date(), 5, 46.350, "52% + 1.5%", 21.914, 109.571,"012915");
   // this.articulosCesta[0] = mock;
    //this.datasource = this.articulosCesta;
    //this.totalRecords = this.datasource.length;

    
    this.cestaService.GetCesta(this.user.codigo)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        cesta => {
          this.CalcularTotales(cesta);
          /*
          let variable = cesta.filter(element => {


            const minDateConst = this.minDate.toISOString().split("T")[0];
            const fecentConst = element.fecent.toISOString().split("T")[0];
            if (fecentConst < minDateConst) {
              return element
            }
          });*/


/*            .forEach(element => {
            element.fecent = this.minDate;
            this.updateCesta(element);
          });*/

          if (this.user.limite - this.totnet < 0 && this.direccionesList[0].portes == "D" && this.user.limite > 0) {
            this.portes = "P";
          }

          if (this.user.limite - this.totnet > 0 && this.direccionesList[0].portes == "D" && this.user.limite>0) {
            this.portes = "D";
          }

          this.dataCesta.changeMessage(cesta.length);


      },
      err => {
        this.messageService.add({ key: 'msg1', severity: 'error', summary: 'Error', detail: err });

      }
    );
  }

  hideDialog() {
    this.articuloDialog = false;
  }

  limitePortes(event) {
    if (this.user.limite - this.totnet <= 0) {
      this.portes = "D";
    }
    return event
  }

  loadCesta(event: LazyLoadEvent) {
    this.loading = true;
    this.lastTableLazyLoadEvent = event;

    // sort by sortfield
    if (event.sortField) {
      this.datasource.sort((data1, data2) => {
        let value1 = data1[event.sortField];
        let value2 = data2[event.sortField];
        let result = null;

        if (value1 == null && value2 != null)
          result = -1;
        else if (value1 != null && value2 == null)
          result = 1;
        else if (value1 == null && value2 == null)
          result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2);
        else
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        return (event.sortOrder * result);
      });
    }

    //imitate db connection over a network
    setTimeout(() => {
      if (this.datasource) {
        this.articulosCesta = this.datasource.slice(event.first, (event.first + event.rows));
        this.loading = false;
      }
    }, 1000);

  }

  deleteArticuloCesta(articuloCesta: B2BCesta) {
    this.isLoading = true;

    this.confirmationService.confirm({
      message: '¿Estás seguro que quieres eliminar el artículo ' + articuloCesta.codart + '?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {

        this.cestaService.deleteArticuloCesta(articuloCesta)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
          res => {
            console.log("entra");

            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Artículo Eliminado', life: 3000 });

            //this.GetCesta();
           // this.articuloDialog = false;

              this.datasource = this.datasource.filter(element => element.linid !== articuloCesta.linid);

              this.CalcularTotales(this.datasource);
              //this.articulosCesta = [...this.datasource];
              this.articuloCesta = null;
              this.codigo = null;
              this.dataCesta.changeMessage(this.datasource.length);

              this.loadCesta(this.lastTableLazyLoadEvent);

            this.updateVisibility();
            this.isLoading = false;

          },
          err => {
            this.isLoading = false;
            this.messageService.add({ key: 'msg1', severity: 'error', summary: 'Error', detail: err });

          }
        );

      },
      reject: () =>{
        this.isLoading = false;

      }

    });
  }


  updateVisibility(): void {
    this.visible = false;
    setTimeout(() => this.visible = true, 0);
  }

}
