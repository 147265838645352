import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
//modelos
import { B2BUser } from '../../../../core/models/b2buser.model';

//servicios
import { AuthenticationService } from '../../../../core/services/authentication.service';

@Component({
  selector: 'app-b2barticulos',
  templateUrl: './b2barticulos.component.html',
  styleUrls: ['./b2barticulos.component.css']
})
export class B2BArticulosComponent implements AfterViewInit, OnInit, OnDestroy {

  //User Variables
  currentUser$: Observable<B2BUser>;
  public user: any = [];

  //Data Variables
  searchText: string = '';
  properties: any =  [{ name: 'codigo', label: "Código" },
    { name: 'nomart', label: "Descripción" },
    { name: 'marca', label: 'Marca' },
    //{ name: 'medidas', label: 'Medida' },
    { name: 'stock', label: "Stock" },
    { name: 'stock10', label: "Disponibilidad" },
    { name: 'precio', label: 'Precio' },
    { name: 'descuento', label: 'Dto.' },
    { name: 'neto', label: 'Precio neto' },
    //{ name: 'codfam', label: 'Família' },
    //{ name: 'canmul', label: 'Cantidad múltiplo' },
    //{ name: 'auxchr6', label: 'Código foto' },
    { name: 'cantidad', label: 'Cantidad' },
    { name: 'auxnum2', label: "Uds/caja" },
  ];



  constructor(public auth: AuthenticationService, private cdRef: ChangeDetectorRef, private route: ActivatedRoute) { }

    //Evento Ciclos de Vida Angular
    ngOnInit(): void {
   
      this.currentUser$ = this.auth.currentUserObs;
      this.user = JSON.parse(localStorage.getItem('currentUser'));
    }
    ngAfterViewInit() {
    }

    ngOnDestroy() {
    }
}
