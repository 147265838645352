import { SafeUrl } from "@angular/platform-browser";

export class B2BArticulosIMG {
  auxchr6?: string;
  url?: string;
  subdirectory?: string;

  constructor(auxchr6: string, url: string) {
    this.auxchr6 = auxchr6;
    this.url = url;
  };


}
   
    
       
       
    
      
      
