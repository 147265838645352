import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../../core/services/authentication.service';

//import { XRSKUser } from 'src/app/core/models/xrskuser.model';
import { B2BUser } from '../../../../core/models/b2buser.model';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, inputArt, TotalCestaService } from '../../../../core/services/data.service';
import { MatPaginator, MatPaginatorIntl  } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { B2BPedidosService } from '../../../../shared/services/b2bPedidos.service';
import { B2BPedidos } from '../../../../shared/models/b2bPedidos.model';
import { LiveAnnouncer } from '@angular/cdk/a11y';

import { DomSanitizer } from '@angular/platform-browser';
import { B2BPerfilService } from '../../../../shared/services/b2bPerfil.service';
import { B2BDirecciones } from '../../../../shared/models/b2bDirecciones.model';
import { DatePipe, formatDate } from '@angular/common';
import { B2BArticulosIMG } from '../../../../shared/models/b2bArticulosIMG.model';
import { FileUploadService } from '../../../../core/services/fileupload.service';
import { ConfirmationService, Message,MessageService } from 'primeng/api';
import { B2BCestaService } from '../../../../shared/services/b2bCesta.service';


  
@Component({
  selector: 'app-b2bsubirpedido',
  templateUrl: './b2bsubirpedido.component.html',
  styleUrls: ['./b2bsubirpedido.component.scss'],
  providers: [MessageService]
})
export class B2BSubirPedidoComponent implements AfterViewInit, OnInit, OnDestroy {
  //Unsubcribe Handler
  private unsubscribe$ = new Subject<void>();


  displayedColumns: string[] = ['docser', 'refter', 'fecha', 'estcab','descargar'];
  sortColumns: string[] = ['Sort by docser', 'Sort by refter', 'Sort by fecha', 'Sort by estcab'];

  properties: any = [{ name: 'docser', label: "Nº Pedido" },
  { name: 'refter', label: "Pedido Cliente" },
  { name: 'fecha', label: "Fecha Pedido" },
  { name: 'estcab', label: "Estado" },
  { name: 'descargar', label: "Descargar" }];


  filterColumns = ["docser", "refter", "fecha", "estcab"];

  currentUser$: Observable<B2BUser>;
  public user: any = [];

  @Input() currentUser: B2BUser;
  public url: any;


  pedidos: Array<B2BPedidos> = new Array<B2BPedidos>();
  pedidosFiltered: Array<B2BPedidos> = new Array<B2BPedidos>();
  dataSource = new MatTableDataSource<B2BPedidos>(this.pedidos);

  public isLoading = false;
  public isLoadingFile = false;
  public submitted: boolean;
  public edit: boolean;
  public pedidoDialog: boolean;

  uploadedFiles: any[] = [];



  constructor(private dataCesta: TotalCestaService, public auth: AuthenticationService, private cdRef: ChangeDetectorRef,
    private pedidosService: B2BPedidosService, private readonly router: Router,
    public _MatPaginatorIntl: MatPaginatorIntl, private el: ElementRef<HTMLElement>,
    private messageService: MessageService, private confirmationService: ConfirmationService,
    private cestaService: B2BCestaService) {


    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

  }

  ngOnInit(): void {

 
    this.currentUser$ = this.auth.currentUserObs;
    this.user = JSON.parse(localStorage.getItem('currentUser'));

    this.cdRef.detectChanges();

  }
  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  openNew() {
    //this.submitted = false;
    //this.edit = true;
    this.pedidoDialog = true;
  }

  hideDialog() {
    this.pedidoDialog = false;
  }


  descargarDocumento(tipo: string) {
    this.isLoading = true;
    this.pedidosService.GetDocumentoArticulos(tipo, this.currentUser.codigo)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
      blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl

          a.download = 'Lista_artículos_B2B.xls';
        
        a.click();
        URL.revokeObjectURL(objectUrl);
          this.isLoading = false;

          this.messageService.add({ key: 'toast', severity: 'success', summary: 'Successful', detail: 'Descarga Completada', life: 3000 });
      },
      err => {
        console.log('error downloading: ', err);
        this.messageService.add({ key: 'message', severity: 'error', summary: 'Error', detail: err.error });

        this.isLoading = false;
      }
    );
  
  }

  myUploader(event) {

    this.confirmationService.confirm({
      message: 'Se borrará la cesta actual y se generará una nueva cesta, ¿Desea Continuar?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {

        this.isLoadingFile = true;
        let formData = new FormData();
        var file = event.files[0];
        var name = event.files[0].name;
        var extension = name.substring(name.lastIndexOf("."), name.legnth);

        //file.name = this.codigo + extension;
        formData.append('file', file, "pedido" + extension);
        formData.append('tercer', this.currentUser.codigo);


        this.pedidosService.upload(formData)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            file => {

              //this.uploadedFiles.push(file);
              //this.articulosIMG.push(file);
              this.messageService.add({ key: 'toast', severity: 'success', summary: 'Successful', detail: 'Cesta Generada', life: 3000 });

              this.isLoadingFile = false;
              this.hideDialog();
              this.GetCesta();

            },
            err => {
              this.messageService.add({ key: 'message', severity: 'error', summary: 'Error', detail: err.error });
              this.isLoadingFile = false;
              this.hideDialog();


            }
          );
      }
    });



  }

  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

  }

  GetCesta(): void {


    this.cestaService.GetCesta(this.user.codigo)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        cesta => {
          this.dataCesta.changeMessage(cesta.length);
        },
        err => {
          this.messageService.add({ key: 'message', severity: 'error', summary: 'Error', detail: err.error });

        });
  }




}
