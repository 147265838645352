import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { B2BPedidos } from 'src/app/shared/models/b2bPedidos.model';
import { retry, catchError, map } from 'rxjs/operators';
import { async } from 'rxjs/internal/scheduler/async';
import { B2BOfertas } from '../models/b2bOfertas.model';

@Injectable({
  providedIn: 'root'
})

export class B2BPedidosService {

  myAppUrl: string;
  myApiUrl: string;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(public http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.myAppUrl = baseUrl;
    this.myApiUrl = "api/b2bpedidos";    
  }

  handleError(error: HttpErrorResponse) {
    console.log("Error Consumiendo B2BArticulosService");
    return throwError(error.error);
  }
  GetPedidos(codcli: string): Observable<B2BPedidos> {

    const params = new HttpParams()
      .set('codcli', codcli);

    return this.http.get<B2BPedidos>(this.myAppUrl + this.myApiUrl + "/pedidos", { 'headers': this.httpOptions.headers, 'params': params })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }

  GetOfertas(codcli: string): Observable<B2BOfertas> {

    const params = new HttpParams()
      .set('codcli', codcli);

    return this.http.get<B2BOfertas>(this.myAppUrl + this.myApiUrl + "/ofertas", { 'headers': this.httpOptions.headers, 'params': params })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }

  GetDocumento(tipo: string, docser: string): Observable<Blob> {

    const params = new HttpParams()
      .set('tipo', tipo)
      .set('docser', docser);

    return this.http.get(this.myAppUrl + this.myApiUrl + "/documento", { 'headers': this.httpOptions.headers, 'params': params, responseType: 'blob' })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }

  GetDocumentoOfe(tipo: string, docser: string): Observable<Blob> {

    const params = new HttpParams()
      .set('tipo', tipo)
      .set('docser', docser);

    return this.http.get(this.myAppUrl + this.myApiUrl + "/documento/ofe", { 'headers': this.httpOptions.headers, 'params': params, responseType: 'blob' })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }

  GetDocumentoArticulos(tipo: string,cliente: string): Observable<Blob> {

    const params = new HttpParams()
      .set('tipo', tipo)
      .set('cliente', cliente);

    return this.http.get(this.myAppUrl + this.myApiUrl + "/documento/articulos", { 'headers': this.httpOptions.headers, 'params': params, responseType: 'blob' })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }

  upload(body): Observable<B2BPedidos> {
    return this.http.post<B2BPedidos>(this.myAppUrl + this.myApiUrl + '/pedido/upload', body)
      .pipe();
  }

 

  generarPedido(formData): Observable<B2BOfertas> {
    return this.http.post<B2BOfertas>(this.myAppUrl + this.myApiUrl + "/oferta/pedido", JSON.stringify(formData), this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }



}
