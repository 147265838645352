import { Component, OnInit, OnDestroy,ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { B2BPerfilService } from '../../../../../shared/services/b2bPerfil.service';

@Component({
  selector: 'app-login',
  templateUrl: './xrsklogin.component.html',
  styleUrls: ['./xrsklogin.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showSpinner = false;
  submitted = false;
  returnUrl: string;
  error = '';
  //Unsubcribe Handler
  private unsubscribe$ = new Subject<void>();

  // Progress Bar values

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  bufferValue = 75;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private perfilService: B2BPerfilService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // reset login status
    this.authenticationService.logout();


  }

  ngOnDestroy(): void {
    //desuscribir subscripciones para evitar memory leaks
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }


  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.showSpinner = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {

          //this.GetDirecciones(data.codigo);
          //localStorage.setItem('returnUrl', this.returnUrl);
          this.router.navigate([this.returnUrl]);


        },
        error => {
          this.error = error.error.message;
          this.showSpinner = false;
        });
  }


}
