import { Component, OnInit, ChangeDetectorRef, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { MatPaginatorIntl  } from '@angular/material/paginator';
//Models
import { B2BUser } from '../../../../core/models/b2buser.model';
import { B2BDirecciones } from '../../../../shared/models/b2bDirecciones.model';
import { B2BPerfilService } from '../../../../shared/services/b2bPerfil.service';
import { B2BFacturas } from '../../../../shared/models/b2bFacturas';
//Import PrimeNG
import { LazyLoadEvent, MessageService, Message } from 'primeng/api';
//Servicios
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { B2BAlbaranes } from '../../../../shared/models/b2bAlbaranes';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-b2bareapersonal',
  templateUrl: './b2bareapersonal.component.html',
  styleUrls: ['./b2bareapersonal.component.scss'],
  providers: [MessageService]
})

export class B2BAreaPersonalComponent implements AfterViewInit, OnInit, OnDestroy {

  //Unsubcribe Handler
  private unsubscribe$ = new Subject<void>();


  //User Variables
  currentUser$: Observable<B2BUser>;
  public user: B2BUser;

  //I/O Variables
  @Input() currentUser: B2BUser;

  //Data Variables
  public estados: any[] = [];
  public direccionFact: B2BDirecciones;

  public direccionesList: B2BDirecciones[] = [];
  public direccionesListEnv: B2BDirecciones[] = [];
  public isLoading = false;
  public isLoadingAlb = false;
  public isLoadingFac = false;

  //Table Variables
  loading: boolean;
  loadingAlb: boolean;

  public datasource: B2BFacturas[] = [];
  public datasourceAlb: B2BAlbaranes[] = [];
  public totalRecords: number;
  public totalRecordsAlb: number;
  public facturas: B2BFacturas[] = [];
  public albaranes: B2BAlbaranes[] = [];
  public unfilteredFac: B2BFacturas[] = [];
  public unfilteredAlb: B2BAlbaranes[] = [];


  properties: any = [{ name: 'docser', label: "Número factura" },
  { name: 'fecha', label: "Fecha Factura" },
  { name: 'basimp', label: "Base Factura" },
  { name: 'cuoimp', label: "Iva factura" },
  { name: 'imptot', label: "Importe factura" },
    { name: 'descargar', label: "Descargar" }];

  propertiesAlb: any = [{ name: 'docser', label: "Número Albaran" },
  { name: 'fecha', label: "Fecha Albaran" },
  { name: 'imptot', label: "Importe Total" },
  { name: 'descargar', label: "Descargar" }];

  //Form variables
  form: FormGroup = new FormGroup({});
  pass: string;
  reppass: string;
  filterColumns: string[];
  public rows: number;


  constructor(public auth: AuthenticationService, private cdRef: ChangeDetectorRef,
    public _MatPaginatorIntl: MatPaginatorIntl, private readonly router: Router, private perfilService: B2BPerfilService,
    private fb: FormBuilder, private actRoute: ActivatedRoute, private messageService: MessageService) {

    this.form = this.fb.group({
      password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]
    }, {
      validator: ConfirmedValidator('password', 'confirm_password')
    })
    this.currentUser$ = this.auth.currentUserObs;
    this.user = this.auth.currentUserValue;
    this.direccionesList = JSON.parse(localStorage.getItem('direcciones'));
    this.direccionesListEnv = this.direccionesList.filter(dire => Number(dire.tipdir) != 0 && !isNaN(Number(dire.tipdir)));
    this.direccionFact = this.direccionesList.filter(dire => dire.tipdir == '0' )[0];


    this.actRoute.data
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.GetAlbaranes(data.albaranes);
        this.GetFacturas(data.facturas);

      })

    this.loading = true;
    this.loadingAlb = true;

    let value = JSON.parse(localStorage.getItem('rows'));
    if (value != undefined && value != null) {
      this.rows = value;

    } else {
      this.rows = 50;
    }
  }

  //Evento Ciclos de Vida Angular
  ngOnInit(): void {


    this.cdRef.detectChanges();

  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  //Funciones propias
  get f() {
    return this.form.controls;
  }

  submit() {
    this.isLoading = true;
    console.log(this.form.value);
    this.isLoading = false;

  }

  changeRows(event) {
    this.rows = event.rows;
    localStorage.setItem('rows', JSON.stringify(this.rows));

  }

  descargarDocumentoAlbaran(tipo: string, docser: string) {
    this.isLoadingAlb = true;
    this.perfilService.GetDocumentoAlbaran(tipo, docser)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
      blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        if (tipo == "pdf") {
          a.download = 'albaran-'+docser+'.pdf';
        }

        a.click();
          URL.revokeObjectURL(objectUrl);

          this.isLoadingAlb = false;

      },
        err => {
          this.isLoadingAlb = false;

        console.log('error downloading: ', err);
        this.messageService.add({ key: 'message', severity: 'error', summary: 'Error', detail: err });

      }
    );
  
  }

  descargarDocumentoFactura(tipo: string, docser: string) {
    this.isLoadingFac = true;

    this.perfilService.GetDocumentoFactura(tipo, docser)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        blob => {
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl

          if (tipo == "pdf") {
            a.download = 'factura-'+docser+'.pdf';
          }
          a.click();
          URL.revokeObjectURL(objectUrl);
          this.isLoadingFac = false;

        },
        err => {
          console.log('error downloading: ', err);
          this.messageService.add({ key: 'messagef', severity: 'error', summary: 'Error', detail: err });
          this.isLoadingFac = false;

        }
      );

  }

  loadFacturas(event: LazyLoadEvent) {
    this.loading = true;
    this.filterColumns = ['docser', 'fecha', 'basimp','cuoimp','imptot'];
    // filter data
    if (Object.keys(event.filters).length > 0) {

      this.datasource = this.unfilteredFac.filter(row => this.filterField(row, event.filters));

    } else {
      this.datasource = this.unfilteredFac;
    }

    // sort by sortfield
    this.datasource.sort((a, b) => this.compareField(a, b, event.sortField) * event.sortOrder);


    //imitate db connection over a network
    setTimeout(() => {
      if (this.datasource) {
        this.facturas = this.datasource.slice(event.first, (event.first + event.rows));
        this.totalRecords = this.datasource.length;
        this.loading = false;
      }
    }, 1000);
  }

  loadAlbaranes(event: LazyLoadEvent) {
    this.loadingAlb = true;
    this.filterColumns = ['docser', 'fecha','imptot'];
    // filter data
    if (Object.keys(event.filters).length > 0) {

      this.datasourceAlb = this.unfilteredAlb.filter(row => this.filterField(row, event.filters));

    } else {
      this.datasourceAlb = this.unfilteredAlb;
    }

    // sort by sortfield
    this.datasourceAlb.sort((a, b) => this.compareField(a, b, event.sortField) * event.sortOrder);



    //imitate db connection over a network
    setTimeout(() => {
      if (this.datasourceAlb) {
        this.albaranes = this.datasourceAlb.slice(event.first, (event.first + event.rows));
        this.totalRecordsAlb = this.datasourceAlb.length;
        this.loadingAlb = false;
      }
    }, 1000);
  }

  GetFacturas(facturas: any): void {
        this.datasource = facturas as unknown as B2BFacturas[];
        this.unfilteredFac = facturas as unknown as B2BFacturas[];
        this.totalRecords = this.datasource.length;
    
  }

  GetAlbaranes(albaranes:any): void {


    this.datasourceAlb = albaranes as unknown as B2BAlbaranes[];
    this.unfilteredAlb = albaranes as unknown as B2BAlbaranes[];
    this.totalRecordsAlb = this.datasourceAlb.length;
      
      
  }

  filterField(row, filter) {
    let isInFilter = false;
    let noFilter = true;

    this.filterColumns.forEach(function (columnName) {
      if (row[columnName] == null) {
        return;
      }
      let rowValue: String;
      if (columnName == "fecha") {
         rowValue = formatDate(row[columnName].toString(), "dd-MM-yyyy", "es").toLowerCase();

      }else if (columnName == "basimp" || columnName == "cuoimp" || columnName == "imptot") {

        rowValue = row[columnName].toString().replace(".",",").toLowerCase();

      }
      else {
        rowValue = row[columnName].toString().toLowerCase();

      }
      noFilter = false;
      let filterMatchMode: String = filter["global"].matchMode;
      if (filterMatchMode.includes("contains") && rowValue.includes(filter["global"].value.toLowerCase())) {
        isInFilter = true;
      }/* else if (filterMatchMode.includes("startsWith") && rowValue.startsWith(filter["global"].value.toLowerCase())) {
        isInFilter = true;
      } else if (filterMatchMode.includes("in") && filter["global"].value.includes(rowValue)) {
        isInFilter = true;
      }*/
    });


    if (noFilter) { isInFilter = true; }

    return isInFilter;
  }

  compareField(rowA, rowB, field: string): number {
    if (rowA[field] == null) return 1; // on considère les éléments null les plus petits
    if (typeof rowA[field] === 'string') {
      return rowA[field].localeCompare(rowB[field]);
    }
    if (typeof rowA[field] === 'number') {
      if (rowA[field] > rowB[field]) return 1;
      else return -1;
    }
  }

  changePassword() {
    let formData = new FormData();
    formData.append('user', this.user.user);
    formData.append('password', this.f.password.value);
    this.auth.changepassword(formData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
       res => {
          this.auth.logout();

          this.router.navigate(['/login']);

          this.cdRef.detectChanges();

          
        },
        err => {
          this.messageService.add({ key: 'messagepwd', severity: 'error', summary: 'Error', detail: err.message });

        });
  }

}

export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
