import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, Input, Inject } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

//import { XRSKUser } from 'src/app/core/models/xrskuser.model';
import { B2BUser } from 'src/app/core/models/b2buser.model';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { B2BArticulos } from '../../../../shared/models/b2bArticulos.model';
import { DataService, inputArt } from '../../../../core/services/data.service';
import { B2BArticulosService } from '../../../../shared/services/b2bArticulos.service';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { FileUploadService } from '../../../../core/services/fileupload.service';
import { DomSanitizer } from '@angular/platform-browser';
import { B2BArticulosIMG } from '../../../../shared/models/b2bArticulosIMG.model';

@Component({
  selector: 'app-b2barticulosimg',
  templateUrl: './b2barticulosimg.component.html',
  styleUrls: ['./b2barticulosimg.component.css'],
  providers: [MessageService]
})
export class B2BArticulosIMGComponent implements OnInit {
  //Unsubcribe Handler
  private unsubscribe$ = new Subject<void>();
  currentUser$: Observable<B2BUser>;
  public user: any = [];

  displayedColumns: string[] = ['codigo', 'imagen', 'edicion'];
  datasource: B2BArticulosIMG[] = [];
  totalRecords: number;
  articuloDialog: boolean;
  artInput = new inputArt();
  loading: boolean;
  articulosIMG: B2BArticulosIMG[] = [];
  articuloIMG: B2BArticulosIMG;
  submitted: boolean;
  url: string;
  codigo: string;
  edit: boolean;
  errorText: string = 'Hi ha un error!';
  searchText: string = '';
  // Progress Bar values
  estado = 'estado';
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  bufferValue = 75;
  @ViewChild('alert', { static: false }) alert: ElementRef;
  @ViewChild('Progress') matProgress: ElementRef;
  properties: any = [{ name: 'auxchr6', label: "Código imagen" },
  { name: 'url', label: "Imagen" },
  { name: 'edicion', label: 'Edición' }];

  uploadedFiles: any[] = [];
  public rows: number;

  constructor(private data: DataService, public auth: AuthenticationService, private cdRef: ChangeDetectorRef, private route: ActivatedRoute,
    private articulosService: B2BArticulosService, private messageService: MessageService, @Inject('BASE_URL') private baseUrl: string,
    private fileUploadSerice: FileUploadService, public domSanitizer: DomSanitizer, private confirmationService: ConfirmationService ) {

    this.url = baseUrl + "api/file/upload";

    let value = JSON.parse(localStorage.getItem('rows'));
    if (value != undefined && value != null) {
      this.rows = value;

    } else {
      this.rows = 50;
    }
  }

  ngOnInit(): void {

    this.currentUser$ = this.auth.currentUserObs;
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.GetArtículosIMG();

  }

  ngOnDestroy() {
    console.log('destroy');
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  changeRows(event) {
    this.rows = event.rows;
    localStorage.setItem('rows', JSON.stringify(this.rows));

  }

  mostrarArticuloIMG(articulo: B2BArticulos) {
    this.articuloIMG = { ...articulo };
    this.edit = true;
    this.articuloDialog = true;
    
  }

  openNew() {
    this.articuloIMG = null;
    this.submitted = false;
    this.edit = false;
    this.articuloDialog = true;
  }
  transform(base64Image) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }


  GetArtículosIMG(): void {

    this.fileUploadSerice.GetArticulosIMGAll()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        articulosIMG => {
          this.articuloDialog = false;
          this.articuloIMG = {};
          this.updateVisibility();
          this.datasource = articulosIMG as unknown as B2BArticulosIMG[];
          this.totalRecords = this.datasource.length;
      },
      err => {
        this.messageService.add({ key: 'msg1', severity: 'error', summary: 'Error', detail: err });

      }
    );
  } 

  hideDialog() {
    this.articuloDialog = false;
  }

  loadArticulosIMG(event: LazyLoadEvent) {
    this.loading = true;

    // sort by sortfield
    if (event.sortField) {
      this.datasource.sort((data1, data2) => {
        let value1 = data1[event.sortField];
        let value2 = data2[event.sortField];
        let result = null;

        if (value1 == null && value2 != null)
          result = -1;
        else if (value1 != null && value2 == null)
          result = 1;
        else if (value1 == null && value2 == null)
          result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2);
        else
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        return (event.sortOrder * result);
      });
    }

    //imitate db connection over a network
    setTimeout(() => {
      if (this.datasource) {
        this.articulosIMG = this.datasource.slice(event.first, (event.first + event.rows));
        this.loading = false;
      }
    }, 1000);

  }

  deleteArticuloIMG(articuloIMG: B2BArticulosIMG) {

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + articuloIMG.auxchr6 + '?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        articuloIMG.subdirectory = "PRODUCTS";

        this.fileUploadSerice.delete(articuloIMG)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
          res => {
            console.log("entra");

            this.messageService.add({ key:'toast1',severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });

            this.GetArtículosIMG();
           // this.articuloDialog = false;

            this.articulosIMG = [...this.datasource];
            this.articuloIMG = null;
            this.codigo = null;

            this.updateVisibility();
         
          },
          err => {

            this.messageService.add({ key: 'msg1', severity: 'error', summary: 'Error', detail: err });

          }
        );

      }
    });
  }

  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

  }

  visible: boolean = true;
  updateVisibility(): void {
    this.visible = false;
    setTimeout(() => this.visible = true, 0);
  }

  myUploader(event) {


    let formData = new FormData();
    var file = event.files[0];
    var name = event.files[0].name;
    var extension = name.substring(name.lastIndexOf("."), name.legnth);

    if (this.edit == true) {
      this.codigo = "-";
    }

    if (this.codigo != null) {


      formData.append('files', file, this.codigo + extension);


      formData.append('auxchr6', this.codigo);

      formData.append('subDirectory', "PRODUCTS");

      if (this.edit == true) {
        formData.append('filename', this.articuloIMG.auxchr6);

      } 

      this.fileUploadSerice.upload(formData)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          res => {
            this.messageService.add({ key: 'toast1', severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
            this.GetArtículosIMG();
          },
          err => {
            this.messageService.add({ key: 'msg1', severity: 'error', summary: 'Error', detail: err.error });
          }
        );

    } else {
      this.messageService.add({ key: 'msg1', severity: 'error', summary: 'Error', detail: "El código no puede estar vacío" });

    }

  }
}
