<div class="main">
  <div class="img_header">
    <img src="assets/images/login_header.jpg" />
  </div>
  <mat-card>
    <mat-progress-bar [color]="color" [mode]="mode" [value]="value" [bufferValue]="bufferValue" [style.display]="showSpinner ? 'block' : 'none'"></mat-progress-bar>
    <mat-card-content>
      <form [formGroup]="loginForm" class="form" (ngSubmit)="onSubmit()">
        <mat-card-header>

          <!--<mat-card-title>Login</mat-card-title>-->
        </mat-card-header>
        <div class="inputbox" style="background-color: #009045;">
          <div class="middle">
            <div class="center">

              <mat-card-content>
                <br>
                <div class="row">
                  <div class="col-md-3" style="display: table; overflow: hidden;">
                    <mat-label style="display: table-cell; vertical-align: middle;">Usuario:</mat-label>
                  </div>
                  <mat-form-field appearance="fill">
                    <input class="form-control" matInput formControlName="username">
                    <div *ngIf="submitted && f.username.errors">
                      <mat-error *ngIf="f.username.errors.required">Username is required</mat-error>
                    </div>
                  </mat-form-field>
                </div>
                <br>
                <div class="row">
                  <div class="col-md-3" style="display: table; overflow: hidden;">
                    <mat-label style="display: table-cell; vertical-align: middle;">Contraseña:</mat-label>
                  </div>
                  <mat-form-field appearance="fill">
                    <input class="form-control" matInput formControlName="password" type="password">
                    <div *ngIf="submitted && f.password.errors">
                      <mat-error *ngIf="f.username.errors.required">Password is required</mat-error>
                    </div>
                  </mat-form-field>
                </div>
                <!--<mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>-->
                <mat-error *ngIf="error" class="alert alert-danger">{{ error }}</mat-error>
              </mat-card-content>
              <mat-card-actions>
                <button mat-raised-button type="submit" color="primary">login</button>
              </mat-card-actions>
            </div>
          </div>
        </div>
      </form>
      <div class="img_footer">
        <img src="assets/images/login_footer.png" />
      </div>
      <div class="img_footer2">
        <img class="img2" src="assets/images/login_footer2.jpg" />
      </div>
    </mat-card-content>
  </mat-card>
</div>
