import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBModule } from 'src/app/mdb.module';
import { MaterialModule } from 'src/app/material.module';
import { NgPrimeModule } from 'src/app/ngprime.module';

import { AppSharedRoutingModule } from './shared-routing.module';
import { B2BAritculosListComponent } from './components/b2b-articulos-list/b2b-articulos-list.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ResizableModule } from 'angular-resizable-element';
import 'hammerjs';


@NgModule({
  imports: [
    CommonModule,
    AppSharedRoutingModule,
    PdfViewerModule,
    ResizableModule,
    MDBModule,
    MaterialModule,
    NgPrimeModule
  ],
  declarations: [
    B2BAritculosListComponent, 
  ],
  //entryComponents: [ModalComponent, ObjtoolbarComponent, FilterPipe, EntidadFilter],
  exports: [
    CommonModule,
    B2BAritculosListComponent,
    PdfViewerModule,

  ]
})

export class AppSharedModule { }
