import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppB2BModule } from './B2B/b2b.modules.module';


@NgModule({
  imports: [
    CommonModule,
    AppB2BModule,
  ],
  declarations: [

  ],
  exports: [
    CommonModule,
    AppB2BModule
  ]
})

export class AppModulesModule { }
