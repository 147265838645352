import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/xrsklogin/xrsklogin.component';
import { RegisterComponent } from './components/xrskregister/xrskregister.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent }
  //{ path: 'usuarios', component: AppUserListComponent },
 // { path: 'usuario/:username', component: AppUserComponent },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppAuthorizationRoutingModule { }
