import { ChangeDetectorRef, Component, OnDestroy, OnInit, OnChanges,ViewEncapsulation, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { delay, Observable, of, Subject, takeUntil } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

//Modelos
import { B2BUser } from '../../models/b2buser.model';
import { XRSKpanelItem } from '../../models/xrskmenuPanel.model';
import { B2BFamilias } from '../../../shared/models/b2bFamilias.model';
import { B2BCategorias } from '../../../shared/models/b2bCategorias.model';
import { B2BDirecciones } from '../../../shared/models/b2bDirecciones.model';
//Servicios
import { AppPanelService } from '../../services/panel.service';
import { B2BPerfilService } from '../../../shared/services/b2bPerfil.service';
import { AuthenticationService } from '../../services/authentication.service';
import { B2BArticulosService } from '../../../shared/services/b2bArticulos.service';
import { DataService,TotalCestaService, inputArt } from '../../services/data.service';

//Material Components
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { B2BCestaService } from '../../../shared/services/b2bCesta.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SidenavComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  //Responsive Variables
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  //Menu Varables
  public menuPanel: XRSKpanelItem[];
  public menuPanel$: Observable<XRSKpanelItem[]>;
  public appPaneles: any = [];

  //User Variables
  public currentUser: B2BUser = new B2BUser();
  public isLoggedIn$: Observable<boolean>;
  public user: B2BUser[] = [];

  //SideNav Variables
  public opened: boolean = true;

  //Data Variables
  public searchCodart: string;
  public searchAlto: string;
  public searchAncho: string;
  public searchLargo: string;
  public familiaList: B2BFamilias[] = []
  public categoriaList: B2BCategorias[] = [];
  public direccionesList: B2BDirecciones[] = [];
  public direccion: B2BDirecciones;
  public direccion$: Observable<B2BDirecciones>;
  public familiaSelected: any = "";
  public categoriaSelected: any = "";
  public totalCesta: number = 0;

  //Header Options
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  //Unsubcribe Handler
  private unsubscribe$ = new Subject<void>();

  constructor(private data: DataService, private dataCesta: TotalCestaService, private cdRef: ChangeDetectorRef, media: MediaMatcher,
    public auth: AuthenticationService, private readonly router: Router, private articulosService: B2BArticulosService,
    public dialog: MatDialog, private _snackBar: MatSnackBar, private panelService: AppPanelService, private cestaService: B2BCestaService,
    private perfilService: B2BPerfilService, private actRoute: ActivatedRoute) {

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => cdRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);



  }
  ngOnChanges() {


  }
  //Evento Ciclos de Vida Angular
  ngOnInit() {





    this.cdRef.detectChanges();

  }

  ngAfterViewInit() {
    this.GetFamilias();

    this.isLoggedIn$ = this.auth.isLoggedInObs;

    this.isLoggedIn$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(loged => {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

        this.menuPanel = this.auth.currentPanelesValue;
        this.menuPanel$ = this.auth.panelesObs;
        this.opened = this.auth.isLoggedInValue;
        this.GetDirecciones(this.currentUser);

        if (this.opened) {
          this.GetTotalCesta();
        }
      })



    this.dataCesta.currentMessage
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(message => {
        this.totalCesta = message;

      });
    this.cdRef.detectChanges();

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    //desuscribir subscripciones para evitar memory leaks
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  GetTotalCesta(): void {

    this.cestaService.GetCesta(this.currentUser.codigo)
      .pipe(takeUntil(this.unsubscribe$),delay(0))
      .subscribe(
        cesta => {
          if (cesta.length > 0) {

            this.dataCesta.changeMessage(cesta.length);


          }


        },
        err => {

        }
      );
  }

  //Funciones propias
  getClass(menuPanel) {
    return "fas fa-" + menuPanel.icon + " fa-sm";
  }

  buscar() {
    this.currentUser = this.auth.currentUserValue;

    let artInput = new inputArt();
    artInput.codcli = this.currentUser.codigo;
    artInput.sqlcond = this.searchCodart != null && this.searchCodart != undefined ? this.searchCodart : "";
    artInput.alto = this.searchAlto != null && this.searchAlto != undefined ? this.searchAlto: "" ;
    artInput.ancho = this.searchAncho != null && this.searchAncho != undefined ?this.searchAncho:"";
    artInput.largo = this.searchLargo != null && this.searchLargo != undefined ? this.searchLargo:"";
    artInput.codcat = this.categoriaSelected != null && this.categoriaSelected != undefined ? this.categoriaSelected:"";
    artInput.codfam = this.familiaSelected != null && this.familiaSelected != undefined ? this.familiaSelected:"";
    if (artInput.codcli != undefined) {

      this.data.changeMessage(artInput);
    }
    this.searchCodart = null;
    this.searchAlto = null;
    this.searchAncho = null;
    this.searchLargo = null;
    this.categoriaSelected = "";
    this.familiaSelected = "";
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/articulos']);
  }

  buscarFamCat() {
    this.currentUser = this.auth.currentUserValue;
    let artInput = new inputArt();
    artInput.codcli = this.currentUser.codigo;
    artInput.sqlcond = null;
    artInput.codcat = this.categoriaSelected;
    artInput.codfam = this.familiaSelected;
    this.data.changeMessage(artInput);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/articulos']);
  }

  GetFamilias(): void {

    this.articulosService.GetFamilias()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
      familias => {
        this.familiaList = familias as unknown as B2BFamilias[];
        //this.familiaSelected = this.familiaList[0].codigo;
        if (this.categoriaSelected == null) {
          this.GetCategorias(this.familiaSelected);
        }
      },
      err => {

      }
    );
  }

  GetDirecciones(user): void {
    if (user != null) {

      this.perfilService.GetDirecciones(user.codigo)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          direcciones => {
            this.direccionesList = direcciones as unknown as B2BDirecciones[];
            this.direccion = this.direccionesList[0];
            localStorage.setItem('direcciones', JSON.stringify(direcciones));
          });

    }
    

  }

  GetCategorias(codfam: string): void {

    if (codfam != null && codfam != undefined && codfam != "") {
      this.articulosService.GetCategorias(codfam)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          categorias => {
            this.categoriaList = categorias as unknown as B2BCategorias[];
            this.categoriaSelected = this.categoriaList[0].codigo;

          },
          err => {

          }
        );
    } else {
      this.categoriaSelected = "";
      this.categoriaList = [];
    }

  }

  logout(): void {
    this.dataCesta.changeMessage(0);
    this.auth.logout();
    this.isLoggedIn$ = this.auth.isLoggedInObs;
    this.menuPanel$ = this.auth.panelesObs;
    this.menuPanel = this.auth.currentPanelesValue;
    this.opened = this.auth.isLoggedInValue;
    //this.isLoggedIn$ = null;
    //this.opened = this.auth.isLoggedInValue;
    //this.opened = false;
    //this.direccion = null;
    //this.direccion$ = null;
    this.router.navigate(['/login']);

    this.cdRef.detectChanges(); // *trigger change here*

  }


}
