import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { B2BArticulos } from 'src/app/shared/models/b2bArticulos.model';
import { retry, catchError, map } from 'rxjs/operators';
import { async } from 'rxjs/internal/scheduler/async';
import { B2BFamilias } from '../models/b2bFamilias.model';
import { B2BCategorias } from '../models/b2bCategorias.model';
import { B2BTipoCliente } from '../models/b2bTipoCliente';

@Injectable({
  providedIn: 'root'
})

export class B2BArticulosService {

  myAppUrl: string;
  myApiUrl: string;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(public http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.myAppUrl = baseUrl;
    this.myApiUrl = "api/b2barticulos";    
  }

  handleError(error: HttpErrorResponse) {
    console.log("Error Consumiendo B2BArticulosService");
    return throwError(error.error);
  }
  GetArticulos(codcli: string, sqlcond: string, tamalt: string, tamanc: string, tamlon:string, codcat: string, codfam: string): Observable<B2BArticulos[]> {


    const params = new HttpParams()
      .set('codcli', codcli)
      .set('sqlcond', sqlcond)
      .set('tamalt', tamalt)
      .set('tamanc', tamanc)
      .set('tamlon', tamlon)
      .set('codcat', codcat)
      .set('codfam', codfam);

    return this.http.get<B2BArticulos[]>(this.myAppUrl + this.myApiUrl + "/articulos", { 'headers': this.httpOptions.headers, 'params': params })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }

  GetFamilias(): Observable<B2BFamilias[]> {
    return this.http.get<B2BFamilias[]>(this.myAppUrl + this.myApiUrl + "/familias", { 'headers': this.httpOptions.headers })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }

  GetTipoCliente(): Observable<B2BTipoCliente[]> {
    return this.http.get<B2BTipoCliente[]>(this.myAppUrl + this.myApiUrl + "/tipocliente", { 'headers': this.httpOptions.headers })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }

  GetCategorias(codfam: string): Observable<B2BCategorias[]> {
    const params = new HttpParams()
      .set('codfam', codfam);

    return this.http.get<B2BCategorias[]>(this.myAppUrl + this.myApiUrl + "/categorias", { 'headers': this.httpOptions.headers, 'params': params })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }



}
