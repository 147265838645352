import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
//import { MdbTableDirective, MdbTablePaginationComponent, MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { B2BArticulosService } from '../../../shared/services/b2bArticulos.service';
import { B2BArticulos } from '../../models/b2bArticulos.model';
import { DataService, inputArt, TotalCestaService } from '../../../core/services/data.service';
import { LazyLoadEvent, MessageService, Message, ConfirmationService } from 'primeng/api';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { B2BArticulosIMG } from '../../models/b2bArticulosIMG.model';
import { FileUploadService } from '../../../core/services/fileupload.service';
import { Subject, takeUntil } from 'rxjs';
import { B2BCestaService } from '../../services/b2bCesta.service';
import { B2BCesta } from '../../models/b2bCesta.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { Table } from 'primeng/table';
import { AppModule } from '../../../app.module'

@Component({
  selector: 'app-b2b-articulos-list',
  templateUrl: './b2b-articulos-list.component.html',
  styleUrls: ['./b2b-articulos-list.component.css'],
  providers: [MessageService]
})
export class B2BAritculosListComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  //Unsubcribe Handler
  private unsubscribe$ = new Subject<void>();

  //I/O Variables
  @Input() b2bUsuario: string;
  @Input() properties: any = [];
  @Input() boxLabel: string;

  //Data Variables
  codfam: string;
  articulos: B2BArticulos[] = [];
  unfilteredArticulos: B2BArticulos[] = [];

  articulo: B2BArticulos;
  articulosIMG: B2BArticulosIMG[] = [];
  articuloIMG: B2BArticulosIMG;
  articuloNotFound: B2BArticulosIMG;
  artInput = new inputArt();
  url: string;
  canofe: number;
  public user: any = [];
  cesta: B2BCesta = new B2BCesta();
  public isLoading = false;
  //Table Variables
  loading: boolean;
  datasource: B2BArticulos[] = [];
  totalRecords: number;
  articuloDialog: boolean;
  displayedColumns: string[] = ['codigo', 'nomart', 'marca', 'stock', 'stock10', 'precio', 'descuento', 'neto','cantidad', 'auxnum2'];
  filterColumns: string[] = ['codigo', 'nomart', 'marca'];
  public rows: number;

  constructor(private data: DataService, private actRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef, private router: Router,
    private articulosService: B2BArticulosService, private messageService: MessageService,
    public domSanitizer: DomSanitizer, private fileService: FileUploadService,
    private cestaService: B2BCestaService, private dataCesta: TotalCestaService,
    public auth: AuthenticationService, private cm: AppModule,
    private confirmationService: ConfirmationService  ) {
    this.user = this.auth.currentUserValue;
    let value = JSON.parse(localStorage.getItem('rows'));
    if (value != undefined && value != null) {
      this.rows = value;

    } else {
      this.rows = 50;
    }
  }

  ngOnChanges(): void {

  }


  ngOnInit(): void {
    this.actRoute.data
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        let articulos = data.articulosResolver;
        this.datasource = articulos as unknown as B2BArticulos[];
        this.unfilteredArticulos = articulos as unknown as B2BArticulos[];

        this.totalRecords = this.unfilteredArticulos.length;
        for (let i = 0; i < this.datasource.length; i++) {
          if (this.datasource[i].auxchr6 != null) {
            this.articulosIMG.push(new B2BArticulosIMG(this.datasource[i].auxchr6, ""));

          }
        }

    })


    this.cdRef.detectChanges();

  }


  ngAfterViewInit() {

    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  changeRows(event) {
    this.rows = event.rows;
    localStorage.setItem('rows', JSON.stringify(this.rows));

  }

  transform(base64Image) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }


  mostrarArticulo(articulo: B2BArticulos) {
    this.articulo = { ...articulo };
    if (articulo.auxchr6 == null || articulo.auxchr6 == undefined || articulo.auxchr6 == "") {
      this.getArticulIMG("", "PANTALLAS");
    }
    else {
      this.getArticulIMG(articulo.auxchr6, "PRODUCTS");
    }
    


    this.articuloDialog = true;
  }

  addArticulo(articulo: B2BArticulos) {

    this.cesta.cabid = 0;
    this.cesta.linid = 0;
    this.cesta.codart = articulo.codigo;
    this.cesta.nomart = articulo.nomart;
    this.cesta.fecha = new Date();
    this.cesta.precio = articulo.precio;
    this.cesta.descuento = "0";
    this.cesta.impnet = 0;
    this.cesta.imptot = 0;
    this.cesta.tercer = this.user.codigo;
    this.cesta.dtob2b = 0;
    this.cesta.canofe = articulo.canofe;
    this.isLoading = true;

    if (articulo.canofe % articulo.canmul) {

      let resto = articulo.canofe % articulo.canmul;
      let cantidad = Math.round(articulo.canofe / articulo.canmul) * articulo.canmul;

      if (cantidad == 0) {
        cantidad = articulo.canmul;
      }


      this.confirmationService.confirm({
        message: 'La cantidad debido al embalaje es ' + cantidad +', ¿Desea Continuar?',
        header: 'Confirmar',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {


          this.cestaService.addCesta(this.cesta)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Producto Añadido', life: 3000 });
              this.GetCesta();
              this.isLoading = false;
              articulo.canofe = null;

            },
              err => {
                this.messageService.add({ key: 'message', severity: 'error', summary: 'Error', detail: err.error });
                this.isLoading = false;

              });
        },   
        reject: () =>{
          this.isLoading = false;

        }
      }
      );

    } else {
      this.cestaService.addCesta(this.cesta)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(res => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Producto Añadido', life: 3000 });
          this.GetCesta();
          this.isLoading = false;
          articulo.canofe = null;

        },
          err => {
            this.messageService.add({ key: 'message', severity: 'error', summary: 'Error', detail: err });
            this.isLoading = false;

          });
    }

  }

  GetCesta(): void {


    this.cestaService.GetCesta(this.user.codigo)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        cesta => {
          this.dataCesta.changeMessage(cesta.length);
        },
        err => {
          this.messageService.add({ key: 'message', severity: 'error', summary: 'Error', detail: err.error });

        });
  }

  loadArticulos(event: LazyLoadEvent) {
    this.loading = true;


    // filter data
    if (Object.keys(event.filters).length > 0) {

      this.datasource = this.unfilteredArticulos.filter(row => this.filterField(row, event.filters))

    } else {
      this.datasource = this.unfilteredArticulos;
    }

    // sort data
    this.datasource.sort((a, b) => this.compareField(a, b, event.sortField) * event.sortOrder);


    //imitate db connection over a network
    setTimeout(() => {
      if (this.datasource) {
        this.articulos = this.datasource.slice(event.first, (event.first + event.rows));
        this.totalRecords = this.datasource.length;
        this.loading = false;
      }
    }, 1000);

  }



  filterField(row, filter) {
    let isInFilter = false;
    let noFilter = true;

    this.filterColumns.forEach(function (columnName) {
      if (row[columnName] == null) {
        return;
      }
      noFilter = false;
      let rowValue = row[columnName].toLowerCase();
      let filterMatchMode = filter["global"].matchMode;
      let searchValue = filter["global"].value.toLowerCase();

      if (filterMatchMode.includes("contains") && rowValue.includes(searchValue)) {
        isInFilter = true;
      }
    });


    if (noFilter) { isInFilter = true; }

    return isInFilter;
  }

  compareField(rowA, rowB, field: string): number {
    if (rowA[field] == null) return 1; // on considère les éléments null les plus petits
    if (typeof rowA[field] === 'string') {
      return rowA[field].localeCompare(rowB[field]);
    }
    if (typeof rowA[field] === 'number') {
      if (rowA[field] > rowB[field]) return 1;
      else return -1;
    }
  }


  getArticulIMG(auxchr6: string, subDirectory: string): void {

    this.fileService.GetArticuloIMG(auxchr6, subDirectory)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        articulo => {
            this.articuloIMG = articulo as unknown as B2BArticulosIMG;
        },
        err => {
          this.messageService.add({ key: 'message', severity: 'error', summary: 'Error', detail: err.error });

        });
  }


  hideDialog() {
    this.articuloDialog = false;
  }

}
