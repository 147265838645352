import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/modules/authorization/auth.guard';

import { B2BDashBoardComponent } from './components/b2bdashboard/b2bdashboard.component';
import { B2BArticulosComponent } from './components/b2barticulos/b2barticulos.component';
import { B2BAreaPersonalComponent } from './components/b2bareapersonal/b2bareapersonal.component';
import { B2BArticulosIMGComponent } from './components/b2barticulosimg/b2barticulosimg.component';
import { B2BCestaComponent } from './components/b2cesta/b2cesta.component';
import { B2BBannerIMGComponent } from './components/b2bbannerimg/b2bbannerimg.component';
import { B2BSubirPedidoComponent } from './components/b2bsubirpedido/b2bsubirpedido.component';
import { ArticulosResolver } from '../../shared/resolvers/articulos.resolver';
import { AlbaranesResolver } from '../../shared/resolvers/albaranes.resolver';
import { FacturasResolver } from '../../shared/resolvers/facturas.resolver';

const routes: Routes = [
  { path: 'dashboard', component: B2BDashBoardComponent, canActivate: [AuthGuard] },
  {
    path: 'articulos',
    component: B2BArticulosComponent,
    canActivate: [AuthGuard],
    resolve: {
      articulosResolver: ArticulosResolver
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'perfil',
    component: B2BAreaPersonalComponent,
    canActivate: [AuthGuard],
    resolve: {
      albaranes: AlbaranesResolver,
      facturas: FacturasResolver,
    },
    runGuardsAndResolvers: 'always',
    
  },
  { path: 'edit_img', component: B2BArticulosIMGComponent, canActivate: [AuthGuard] },
  { path: 'banner_img', component: B2BBannerIMGComponent, canActivate: [AuthGuard] },
  { path: 'cesta', component: B2BCestaComponent, canActivate: [AuthGuard] },
  { path: 'subir_ped', component: B2BSubirPedidoComponent, canActivate: [AuthGuard] },
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppB2BRoutingModule { }
