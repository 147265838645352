  <div class="example-container" [class.example-is-mobile]="mobileQuery.matches">

  <nav class="navbar navbar-expand-lg navbar-light primary" *ngIf="isLoggedIn$ | async">
    <div class="container-fluid">
      <div  class="navbar-brand me-2"><img src="assets/images/logo_eurobearings.png" width="180" [routerLink]="['/']" class="img-fluid"><a *ngIf="direccion" class="navbar-brand taskerTitle ml-5">Agente Comercial: {{direccion.nomage}}</a></div>
      <ul class="navbar-nav d-flex flex-row">
        <li class="nav-item dropdown" mdbDropdown>
          <a class="nav-link dropdown-toggle"
             id="navbarDropdownMenuLink"
             role="button"
             mdbDropdownToggle
             aria-expanded="false">
            <img src="assets/images/user.png" style="height: 34px;width: 34px" class="img-fluid"><span *ngIf="direccion" class="caret">{{direccion.empres}}</span><span *ngIf="!direccion" class="caret">{{currentUser.nombre}}</span>
          </a>
          <ul mdbDropdownMenu
              class="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink">
            <li>
              <a class="dropdown-item waves-light" [routerLink]="['/perfil']">Perfil</a>
            </li>
            <li>
              <a class="dropdown-item waves-light" (click)="logout()">Log out</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>


<mat-sidenav-container class="example-sidenav-container" autosize="true">
  <mat-sidenav #sidenav opened="opened" mode="side" [fixedInViewport]="false">
    <div class="view">
      <div *ngIf="isLoggedIn$ | async" class="p-4">
        <img src="assets/images/user.png" style="height: 34px; width: 34px;z-index:2;" class="img-fluid"><br><br>
        <div *ngIf="direccion" class="text-white" style="z-index:2;word-break:break-word">{{direccion.empres}}</div>
      </div>
    </div>
    <mat-accordion *ngIf="isLoggedIn$ | async" multi>
      <ng-container *ngFor="let panel$ of (menuPanel$ | async)">
        <mat-expansion-panel disabled [expanded]="true" *ngIf="panel$.name=='Inicio'">
          <mat-expansion-panel-header>
            <div class="row" [routerLink]="panel$.menu[0].route">
              <div class="col-md-2">
                <i [className]="getClass(panel$)"></i>
              </div>
              <div class="col-md">
                <mat-panel-title>{{panel$.name}}</mat-panel-title>
              </div>
            </div>
          </mat-expansion-panel-header>
          <ul>
            <li>
              <mat-label>Artículo</mat-label><br>
              <mat-form-field class="float-left" appearance="fill">
                <input matInput [(ngModel)]="searchCodart" id="search" matNativeControl (keydown.enter)="buscar()">
              </mat-form-field>
              <div class="grid p-fluid">
                <div class="col-12 md:col-4">
                  <mat-label>Interior</mat-label><br>
                  <div class="p-inputgroup">

                    <mat-form-field appearance="fill" style="width:2vw">
                      <input matInput [(ngModel)]="searchAlto" id="search" matNativeControl (keydown.enter)="buscar()">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-12 md:col-4">
                  <mat-label>Exterior</mat-label><br>
                  <div class="p-inputgroup">

                    <mat-form-field appearance="fill" style="width:2vw">
                      <input matInput [(ngModel)]="searchAncho" id="search" matNativeControl (keydown.enter)="buscar()">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-12 md:col-4">
                  <mat-label>Grueso</mat-label><br>
                  <div class="p-inputgroup">

                    <mat-form-field appearance="fill" style="width:2vw">
                      <input matInput [(ngModel)]="searchLargo" id="search" matNativeControl (keydown.enter)="buscar()">
                    </mat-form-field>
                  </div>

                </div>
              </div>

            </li>
            <li>
              <mat-label>
                Búsqueda guiada
              </mat-label><br>
              <mat-form-field class="float-left" appearance="fill">
                <mat-select [(ngModel)]="familiaSelected" (selectionChange)="GetCategorias(familiaSelected)">
                  <mat-option [value]=""></mat-option>
                  <mat-option *ngFor="let option of familiaList" [value]="option.codigo">{{ option.nombre }}</mat-option>
                </mat-select>
              </mat-form-field><br>
              <mat-form-field class="float-left" appearance="fill">
                <mat-select [(ngModel)]="categoriaSelected">
                  <mat-option [value]=""></mat-option>
                  <mat-option *ngFor="let option of categoriaList" [value]="option.codigo">{{ option.nombre }}</mat-option>
                </mat-select>
              </mat-form-field><br>
              <button type="button" class="btn btn-primary" (click)="buscar()">
                Buscar
              </button>
            </li>
          </ul>
        </mat-expansion-panel>
        <mat-expansion-panel disabled [expanded]="true" *ngIf="panel$.name!='Gestor ISB' && panel$.name!='Inicio' && panel$.name!='Cesta y Pedido' && panel$.name !='Subir pedido'">
          <mat-expansion-panel-header>
            <div class="row" [routerLink]="panel$.menu[0].route" *ngIf="panel$.name!='Cesta y Pedido'">
              <div class="col-md-2">
                <i [className]="getClass(panel$)"></i>
              </div>
              <div class="col-md">
                <mat-panel-title>{{panel$.name}}</mat-panel-title>
              </div>

            </div>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
        <mat-expansion-panel disabled [expanded]="true" *ngIf="panel$.name=='Subir pedido' && currentUser.b2b_cesta == 'S'">
          <mat-expansion-panel-header>
            <div class="row" [routerLink]="panel$.menu[0].route" *ngIf="panel$.name=='Subir pedido'">
              <div class="col-md-2">
                <i [className]="getClass(panel$)"></i>
              </div>
              <div class="col-md">
                <mat-panel-title>{{panel$.name}}</mat-panel-title>
              </div>

            </div>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
        <mat-expansion-panel disabled [expanded]="true" *ngIf="panel$.name=='Cesta y Pedido' && currentUser.b2b_cesta == 'S'">
          <mat-expansion-panel-header>
            <div class="row" [routerLink]="panel$.menu[0].route" *ngIf="panel$.name=='Cesta y Pedido'">
              <div class="col-md-2">
                <i [className]="getClass(panel$)"></i>
              </div>
              <div class="col-md">
                <mat-panel-title>{{panel$.name}} ({{totalCesta}})</mat-panel-title>
              </div>
            </div>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
        <mat-expansion-panel disabled [expanded]="true" *ngIf="panel$.name=='Gestor ISB'">
          <mat-expansion-panel-header>
            <div class="row" [routerLink]="panel$.menu[0].route">
              <div class="col-md-2">
                <i [className]="getClass(panel$)"></i>
              </div>
              <div class="col-md">
                <mat-panel-title>{{panel$.name}}</mat-panel-title>
              </div>
            </div>
          </mat-expansion-panel-header>
          <mat-nav-list>
            <ng-container *ngFor="let nav of panel$.menu">
              <a mat-list-item [routerLink]="nav.route">
                {{nav.name}}
              </a>
            </ng-container>
          </mat-nav-list>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

</div>
