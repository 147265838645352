export class B2BOfertas {
  cabid: number;

  refter: string;
  docser: string;
  tercer: string;
  nombre: string;
  fecfin: string;
  fecha: string;

  imptot: number;
  estcab: string;



  constructor() {};
 

}
   
    
       
       
    
      
      
