import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MDBModule } from '../../mdb.module';
import { NgPrimeModule } from '../../ngprime.module';
import { MaterialModule } from '../../material.module';
import { AppSharedModule } from '../../shared/shared.module';
import { AppB2BRoutingModule } from './b2b-routing.module';

import { B2BDashBoardComponent } from './components/b2bdashboard/b2bdashboard.component';
import { B2BSubirPedidoComponent } from './components/b2bsubirpedido/b2bsubirpedido.component';
import { B2BArticulosComponent } from './components/b2barticulos/b2barticulos.component';
import { B2BArticulosIMGComponent } from './components/b2barticulosimg/b2barticulosimg.component';
import { B2BBannerIMGComponent } from './components/b2bbannerimg/b2bbannerimg.component';

import { B2BAreaPersonalComponent } from './components/b2bareapersonal/b2bareapersonal.component';
import { B2BCestaComponent } from './components/b2cesta/b2cesta.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { StylePaginatorDirective } from '../../directives/style-paginator.directive';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
    imports: [
        CommonModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        MaterialModule,
        NgPrimeModule,
        DragDropModule,
        MatNativeDateModule,
        HttpClientModule,
        FormsModule,
        RouterModule,
        AppSharedModule,
        AppB2BRoutingModule,
        PdfViewerModule,
        MDBModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        B2BDashBoardComponent,
        B2BArticulosComponent,
        B2BAreaPersonalComponent,
        B2BCestaComponent,
        StylePaginatorDirective,
        B2BArticulosIMGComponent,
        B2BBannerIMGComponent,
        B2BSubirPedidoComponent
    ],
    exports: [
        CommonModule,
        AppB2BRoutingModule,
        PdfViewerModule,
        RouterModule,
        FormsModule
    ],
    providers: [
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
        MessageService, ConfirmationService
    ]
})

export class AppB2BModule {

}
