<p-toast></p-toast>
<p-messages key="message"></p-messages>

<div class="grid">
  <div class="col-12">
    <h1>Todos los productos</h1>
    <p-panel styleClass="bg-panel" header="{{boxLabel}}" [toggleable]="true">
      <p-table [columns]="properties" [lazy]="true" (onLazyLoad)="loadArticulos($event)" [loading]="loading" [value]="articulos"
               styleClass="p-datatable-striped" [rows]="rows" (onPage)="changeRows($event)" [paginator]="true" [totalRecords]="totalRecords"
               [rowHover]="true" dataKey="id"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[10,25,50,100]" #dt>
        <ng-template pTemplate="caption">
          <div class="p-d-flex p-ai-center p-jc-between">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" class="search-filter" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Filtro..." />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th></th>
            <ng-container *ngFor="let head of columns;  let j = index">
              <th *ngIf="head.name != 'cantidad' && head.name != 'stock10' && head.name == displayedColumns[j]" class="text-left" [pSortableColumn]="head.name">{{head.label}} <p-sortIcon [field]="head.name"></p-sortIcon></th>
              <th *ngIf="head.name == 'cantidad' && user.b2b_cesta == 'S'" class="text-center">{{head.label}}</th>
              <th colspan="2" *ngIf="head.name == 'stock10' && head.name == displayedColumns[j]" class="text-center" [pSortableColumn]="head.name">
                {{head.label}} <p-sortIcon [field]="head.name"></p-sortIcon>
              </th>
            </ng-container>
          </tr>
          <tr>
            <th></th>
            <ng-container *ngFor="let head of columns;  let j = index">
              <th *ngIf="head.name != 'cantidad' && head.name != 'stock10' && head.name == displayedColumns[j]" class="text-left"></th>
              <th *ngIf="head.name == 'stock10' && head.name == displayedColumns[j]" class="text-left">
                <div><span class="form-text text-muted">a 10 días</span></div>
              </th>
              <th *ngIf="head.name == 'stock10' && head.name == displayedColumns[j]" class="text-left">
                <div><span class="form-text text-muted">a 30 días</span></div>
              </th>
            </ng-container>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-element let-columns="columns" let-i="rowIndex">
          <tr [ngClass]="{'stock-color':element.stock == 0,'row-color':element.stock > 0 }">
            <td>
              <button pButton pRipple type="button" icon="pi pi-search-plus" (click)="mostrarArticulo(element)" class="ml-1 p-button-outlined p-button-info"></button>
            </td>
            <ng-container *ngFor="let head of columns; let j = index">
              <td class="text-left" *ngIf="head.name != 'cantidad' && head.name != 'precio' && head.name != 'stock' && head.name != 'neto' && head.name != 'stock10' && head.name != 'descuento' && head.name == displayedColumns[j]"> {{element[head.name]}} </td>
              <td class="text-left" *ngIf="head.name == 'descuento' && head.name == displayedColumns[j] && element['precio'] > 0"> {{element[head.name]}} </td>
              <td class="text-left" *ngIf="head.name == 'descuento' && head.name == displayedColumns[j] && element['precio'] == 0">  </td>

              <td class="text-left" *ngIf="head.name == 'precio' && head.name == displayedColumns[j] && element['precio'] > 0"> {{element[head.name] | currency:'EUR':'symbol':'1.2-2'}} </td>
              <td class="text-left" *ngIf="head.name == 'precio' && head.name == displayedColumns[j] && element['precio'] == 0"><strong> A CONSULTAR </strong></td>

              <td class="text-left" *ngIf="head.name == 'stock' && head.name == displayedColumns[j] && element['stock'] > 0">
                <strong>{{element['stock']}}</strong>
              </td>
              <td class="text-left" *ngIf="head.name == 'stock' && head.name == displayedColumns[j] && element['stock'] == 0">
                {{element['stock']}}
              </td>
              <td class="text-left" *ngIf="head.name == 'stock10' && head.name == displayedColumns[j]">
                {{element['stock10']}}
              </td>
              <td class="text-left" *ngIf="head.name == 'stock10' && head.name == displayedColumns[j]">
                {{element['stock30']}}
              </td>
              <td class="text-left" *ngIf="head.name == 'neto' && head.name == displayedColumns[j] && element['neto'] > 0"> <strong>{{element[head.name] | currency:'EUR':'symbol':'1.3-3'}}</strong> </td>
              <td class="text-left" *ngIf="head.name == 'neto' && head.name == displayedColumns[j] && element['neto'] == 0">  </td>
              <td class="text-center" [style]="{width: '14rem'}" *ngIf="head.name == 'cantidad' && user.b2b_cesta == 'S'">
                <span>
                  <input type="number" [style]="{width: '7rem'}" pInputText [(ngModel)]="element.canofe" (keyup.enter)="addArticulo(element)">
                  <button pButton pRipple type="button" icon="pi pi-shopping-cart" class="ml-1 p-button-info" [loading]="isLoading" [disabled]="!(element.canofe!=null && element.canofe >0)" (click)="addArticulo(element)"></button>
                </span>
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </p-table>
    </p-panel>
  </div>
</div>

<p-dialog [(visible)]="articuloDialog" [style]="{width: '940px'}" header="Producto" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <div class="field">
      <div class="formgrid grid">
        <div *ngIf="articuloIMG" class="col-6"  [style]="{width: '450px'}">
          <img [src]="transform(articuloIMG.url)" class="product-image">
        </div>
        <div class="col-6" [style]="{width: '450px'}">
          <div class="col-12">
            <h2>{{articulo.nomart}}</h2>
          </div>
          <div class="col-12">
            <b>Código: </b>
            <span> {{articulo.codigo}}</span>
          </div>
          <div class="col-12">
            <b>Descripción: </b>
            <span>{{articulo.nomart}}</span>
          </div>
          <div class="col-12">
            <b>Marca: </b>
            <span> {{articulo.marca}}</span>
          </div>
          <div class="col-12">
            <b>Família: </b>
            <span> {{articulo.codfam1}}</span>
          </div>
          <div class="col-12">
            <div><b>Subfamilia: </b><span> {{articulo.codfam2}}</span></div>
            <div *ngIf="articulo.codfam3"><span class="text-white">Subfamilia: </span><span> {{articulo.codfam3}}</span></div>
            <div *ngIf="articulo.codfam4"><span class="text-white">Subfamilia: </span><span> {{articulo.codfam4}}</span></div>
          </div>
          <div class="col-12">
            <b>Medida: </b>
            <span> {{articulo.medidas}}</span>
          </div>
          <div class="col-12">
            <b>Uds. por caja: </b>
            <span> {{articulo.auxnum2}}</span>
          </div>
          <div class="col-12">
            <b>Cantidad mínima de pedido: </b>
            <span> {{articulo.canmul}}</span>
          </div>
          <div class="col-12">
            <b>Código arancelario: </b>
            <span> {{articulo.paranc}}</span>
          </div>
          <div class="col-12">
            <b>Peso en Kg.: </b>
            <span> {{articulo.pesnet}}</span>
          </div><br />
          <div class="col-12">
            <b>Stock Disponible hoy: </b>
            <span> {{articulo.stock}}</span>
          </div>
          <div class="col-12">
            <b>Stock Disponible a 10 días: </b>
            <span> {{articulo.stock10}}</span>
          </div>
          <div class="col-12">
            <b>Stock Disponible a 30 días: </b>
            <span> {{articulo.stock30}}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cerrar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

