export class B2BUser {
  user: string;
  username: string;
  idioma: string;
  tipo: string;
  codigo: string;
  changepass: number;
  status: number;
  token: string;
  expiresIn: Date;
  axional: string;
  tipcli: string;
  nomtip: string;
  minped: number;
  b2b_banner: string;
  b2b_cesta: string;
  limite: number;
  distribuidor: number;

  constructor() {}
}


