import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService
{

  private messageSource: BehaviorSubject<inputArt> = new BehaviorSubject(new inputArt());
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(message: inputArt)
  {
    this.messageSource.next(message)
  }

  public get currentData(): inputArt {
    return this.messageSource.value;
  }

}

@Injectable()
export class TotalCestaService {

  private messageSource: BehaviorSubject<number> = new BehaviorSubject(0);
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(message: number) {
    this.messageSource.next(message)
  }

}

export class inputArt {
  codcli: string;
  sqlcond: string;
  codfam: string;
  codcat: string;
  alto: string;
  ancho: string;
  largo: string;
}
