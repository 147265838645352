
<body>
  <!--<app-nav-menu></app-nav-menu>-->
  <app-sidenav></app-sidenav>
  <!--
  <div class="container">
    <app-sidenav></app-sidenav>
  </div>
    -->

  <div class="container">
    <router-outlet></router-outlet>
  </div>
</body>
