import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { B2BCategorias} from 'src/app/shared/models/b2bCategorias.model';
import { retry, catchError, map } from 'rxjs/operators';
import { async } from 'rxjs/internal/scheduler/async';
import { B2BDirecciones } from '../models/b2bDirecciones.model';
import { B2BFacturas } from '../models/b2bFacturas';
import { B2BAlbaranes } from '../models/b2bAlbaranes';

@Injectable({
  providedIn: 'root'
})

export class B2BPerfilService {

  myAppUrl: string;
  myApiUrl: string;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(public http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.myAppUrl = baseUrl;
    this.myApiUrl = "api/b2bareapersonal";
  }

  handleError(error: HttpErrorResponse) {
    console.log("Error Consumiendo B2BArticulosService");
    return throwError(error.error);
  }
  GetDirecciones(codcli: string): Observable<B2BDirecciones[]> {

    const params = new HttpParams()
      .set('codcli', codcli);

    return this.http.get<B2BDirecciones[]>(this.myAppUrl + this.myApiUrl + "/direcciones", { 'headers': this.httpOptions.headers, 'params': params })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }

  GetFacturas(codcli: string): Observable<B2BFacturas[]> {

    const params = new HttpParams()
      .set('codcli', codcli);

    return this.http.get<B2BFacturas[]>(this.myAppUrl + this.myApiUrl + "/facturas", { 'headers': this.httpOptions.headers, 'params': params })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }

  GetAlbaranes(codcli: string): Observable<B2BAlbaranes[]> {

    const params = new HttpParams()
      .set('codcli', codcli);

    return this.http.get<B2BAlbaranes[]>(this.myAppUrl + this.myApiUrl + "/albaranes", { 'headers': this.httpOptions.headers, 'params': params })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }


  GetDocumentoFactura(tipo: string, docser: string): Observable<Blob> {

    const params = new HttpParams()
      .set('tipo', tipo)
      .set('docser', docser);

    return this.http.get(this.myAppUrl + this.myApiUrl + "/factura/documento", { 'headers': this.httpOptions.headers, 'params': params, responseType: 'blob' })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }

  GetDocumentoAlbaran(tipo: string, docser: string): Observable<Blob> {

    const params = new HttpParams()
      .set('tipo', tipo)
      .set('docser', docser);

    return this.http.get(this.myAppUrl + this.myApiUrl + "/albaran/documento", { 'headers': this.httpOptions.headers, 'params': params, responseType: 'blob' })
      .pipe(
        //retry(1),
        catchError(this.handleError)
      );
  }






}
