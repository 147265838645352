export class B2BCesta {
  cabid: number;
  linid: number;
  codart: string;
  nomart: string;
  fecha: Date;
  canofe: number;
  precio: number;
  descuento: string;
  impnet: number;
  imptot: number;
  tercer: string;
  dtob2b: number;
  refter: string;
  direnv: string;
  coment: string;
  fecent: Date;
  tiptra: string;
  portes: string;

  constructor() {};
  /*
  constructor(cabid: number, linid: number, codart: string, nomart: string,
    fecha: Date, canofe: number, precio: number, descuento: string,
    impnet: number, imptot: number, tercer: string, dtob2b:number) {
    this.cabid = cabid;
    this.linid =linid;
    this.codart=codart;
    this.nomart=nomart;
    this.fecha =fecha;
    this.canofe=canofe;
    this.precio=precio;
    this.descuento = descuento;
    this.impnet=impnet;
    this.imptot = imptot;
    this.tercer = tercer;
    this.dtob2b = dtob2b;
  };*/

}
   
    
       
       
    
      
      
