import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError, catchError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
//import { XRSKUser } from '../models/xrskuser.model';
import { B2BUser } from 'src/app/core/models/b2buser.model';
import { AppPanelService } from 'src/app/core/services/panel.service';
import { XRSKpanelItem } from 'src/app/core/models/xrskmenuPanel.model';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  myAppUrl: string;
  myApiUrl: string;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private currentUserSubject: BehaviorSubject<B2BUser>;
  public currentUser: Observable<B2BUser>;
  private panelesValue: BehaviorSubject<XRSKpanelItem[]>;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(private readonly http: HttpClient, private panelService: AppPanelService, @Inject('BASE_URL') baseUrl: string) {
    this.currentUserSubject = new BehaviorSubject<B2BUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.panelesValue = <BehaviorSubject<XRSKpanelItem[]>>new BehaviorSubject([]);

    if (JSON.parse(localStorage.getItem('currentUser')) != null) {
      this.loggedIn.next(true);
      // Si ya estábamos conectados recuperamos los proyectos.
      //this.getSPSProyectosAbiertos();
    }


    if (JSON.parse(localStorage.getItem('appPaneles')) != null) {
      this.panelesValue.next(JSON.parse(localStorage.getItem('appPaneles')));
      // Si ya estábamos conectados recuperamos los proyectos.
      //this.getSPSProyectosAbiertos();
    }

    this.myAppUrl = baseUrl;
    this.myApiUrl = "api/auth";
  }

  handleError(error: HttpErrorResponse) {
    console.log("Error Consumiendo AuthenticationService");
    return throwError(error.error);
  }

  public get currentUserValue(): B2BUser {
    return this.currentUserSubject.value;
  }

  public get isLoggedInValue() {
    return this.loggedIn.value;
  }
  public get isLoggedInObs() {
    return this.loggedIn.asObservable();
  }

  public get currentPanelesValue() {
    return this.panelesValue.value;
  }

  public get currentUserObs() {
    return this.currentUserSubject.asObservable();
  }

  public get panelesObs() {
    return this.panelesValue.asObservable();
  }

  login(username: string, password: string) {
    return this.http.post<any>(this.myAppUrl + this.myApiUrl+"/login", { username, password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('rows', JSON.stringify(50));

          this.currentUserSubject.next(user);
          this.loggedIn.next(true);
          this.getAppPaneles();
          //this.router.navigate(['/']);
        }

        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('appPaneles');
    localStorage.removeItem('direcciones');
    localStorage.removeItem('rows');
    this.loggedIn.next(false);
    this.currentUserSubject.next(null);
    this.panelesValue.next(null);

  }

  register(userRegistration: any) {
    //return this.http.post('/api/auth', userRegistration).pipe(catchError(this.handleError));
    return this.http.post(this.myAppUrl + this.myApiUrl + "/register", userRegistration)
      .pipe();
  }

  changepassword(formData) {
    return this.http.post<any>(this.myAppUrl + this.myApiUrl + "/changepassword", formData)
      .pipe(
        catchError(this.handleError)
      );

  }


  getAppPaneles(): void {

    this.panelService.getAppPaneles().subscribe(
      paneles => {
        this.panelesValue.next(paneles);
        localStorage.setItem('appPaneles', JSON.stringify(paneles));
      },
      err => {
        var j = 2;
      }
    );
  }

}
