import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
import { MDBModule } from '../mdb.module';
import { AppAuthorizationModule } from './modules/authorization/authorization.module';
import { SidenavComponent } from './components/sidenav/sidenav.component';


@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        AppAuthorizationModule,
        MDBModule
    ],
    declarations: [
        SidenavComponent,
        //MDBModule
    ],
    exports: [
        SidenavComponent,
        RouterModule
    ]
})

export class AppCoreModule { }
