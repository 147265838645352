import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
//import { XRSKUser } from '../models/xrskuser.model';
import { map } from 'rxjs/operators';
import { B2BArticulosIMG } from '../../shared/models/b2bArticulosIMG.model';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  myAppUrl: string;
  myApiUrl: string;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(private readonly http: HttpClient, @Inject('BASE_URL') baseUrl: string) {

    this.myAppUrl = baseUrl;
    this.myApiUrl = "api/file";
  }


  GetArticuloIMG(auxchr6: string, subDirectory: string): Observable<B2BArticulosIMG> {

    const params = new HttpParams()
      .set('auxchr6', auxchr6)
      .set('subDirectory', subDirectory);

    return this.http.get<B2BArticulosIMG>(this.myAppUrl + this.myApiUrl + "/articuloIMG", { 'headers': this.httpOptions.headers, 'params': params })
      .pipe(
        //retry(1),
      );
  }

  GetArticulosIMG(articulosIMG: B2BArticulosIMG[]): Observable<B2BArticulosIMG[]> {

    return this.http.post<B2BArticulosIMG[]>(this.myAppUrl + this.myApiUrl + "/articulosIMG", articulosIMG )
      .pipe(
        //retry(1),
      );
  }
  GetArticulosIMGAll(): Observable<B2BArticulosIMG[]> {

    return this.http.post<B2BArticulosIMG[]>(this.myAppUrl + this.myApiUrl + "/articulosIMGALL", {})
      .pipe(
        //retry(1),
      );
  }

  GetBannerIMGAll(formdata): Observable<B2BArticulosIMG[]> {

    return this.http.post<B2BArticulosIMG[]>(this.myAppUrl + this.myApiUrl + "/bannerIMGALL", formdata)
      .pipe(
        //retry(1),
      );
  }

  upload(body): Observable<B2BArticulosIMG> {
    return this.http.post<B2BArticulosIMG>('/api/file/upload', body)
      .pipe();
  }

  delete(body){
    return this.http.post('/api/file/delete', body)
      .pipe();
  }







}
