import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { catchError, empty } from 'rxjs';
import { DataService } from '../../core/services/data.service';
import { B2BPerfilService } from '../services/b2bPerfil.service';
@Injectable()
export class DireccionesResolver implements Resolve<any> {
  constructor(private data: DataService, public perfilService: B2BPerfilService) {

  }
  resolve(route: ActivatedRouteSnapshot) {

    let user = JSON.parse(localStorage.getItem('currentUser'));
    return this.perfilService.GetDirecciones(user.codigo).pipe(
        catchError((error) => {
          return empty();
        })
      );

   }
   
}
