 import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';

import { MaterialModule } from './material.module';
import { MDBModule } from './mdb.module';
import { NgPrimeModule } from './ngprime.module';
import { AppModulesModule } from './modules/app.modules.module';
import { AppSharedModule } from './shared/shared.module';
import { AppCoreModule } from './core/core.module';
import { errorInterceptorProvider} from './core/modules/authorization/error.interceptor';
import { jwtInterceptorProvider } from './core/modules/authorization/jwt.interceptor';
import { AppDateAdapter, APP_DATE_FORMATS } from '../app/shared/format/format-datepicker';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import localeEs from '@angular/common/locales/es';
import { DatePipe, registerLocaleData } from '@angular/common';
import { ResizableModule } from 'angular-resizable-element';



import 'hammerjs';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DataService, TotalCestaService } from './core/services/data.service';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';
import { ArticulosResolver } from './shared/resolvers/articulos.resolver';
import { AlbaranesResolver } from './shared/resolvers/albaranes.resolver';
import { FacturasResolver } from './shared/resolvers/facturas.resolver';
import { DireccionesResolver } from './shared/resolvers/direcciones.resolver';

registerLocaleData(localeEs, 'es');

@NgModule({

  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    MatNativeDateModule,
    MaterialModule,
    MDBModule,
    NgPrimeModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    AppModulesModule,
    AppSharedModule,
    AppCoreModule,
    AppRoutingModule,
    ResizableModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      //enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately'
    }),
    MdbAccordionModule,
    MdbCarouselModule,
    MdbCheckboxModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbFormsModule,
    MdbModalModule,
    MdbPopoverModule,
    MdbRadioModule,
    MdbRangeModule,
    MdbRippleModule,
    MdbScrollspyModule,
    MdbTabsModule,
    MdbTooltipModule,
    MdbValidationModule,
  ],
  exports: [

    MatNativeDateModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    ResizableModule,
    ReactiveFormsModule,
  
  ],
  providers: [
    jwtInterceptorProvider,
    errorInterceptorProvider,
    DatePipe,
    DataService,
    TotalCestaService,
    ArticulosResolver,
    AlbaranesResolver,
    FacturasResolver,
    DireccionesResolver,
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: DateAdapter, useClass: AppDateAdapter }
  ],
  bootstrap: [AppComponent],  
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {

}



