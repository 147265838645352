<p-toast></p-toast>

<div class="container-fluid w-100 ml-5">
  <div class="grid">
    <div class="col-11 mt-4">
      <h1>Información cesta</h1>
      <p-messages key="msg1"></p-messages>
      <p-toolbar styleClass="p-mb-4 mt-4">
        <ng-template pTemplate="left">
          <button pButton pRipple label="Borrar cesta" class="p-button-primary p-mr-2" (click)="deleteCesta()"></button>
        </ng-template>
      </p-toolbar>
      <p-panel styleClass="bg-panel" header="Carrito" [toggleable]="true">
        <p-table *ngIf="visible" #dt [columns]="properties" [lazy]="true" (onLazyLoad)="loadCesta($event)" [loading]="loading" [value]="articulosCesta"
                 styleClass="p-datatable-striped" [rows]="rows" (onPage)="changeRows($event)" [paginator]="true" [totalRecords]="totalRecords"
                 [rowHover]="true" dataKey="id"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
                 [rowsPerPageOptions]="[10,25,50,100]">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let head of columns;  let j = index">
                <th *ngIf="head.name == 'codart' || head.name == 'nomart' || head.name == 'fecent'" class="text-left">{{head.label}}</th>
                <th *ngIf="head.name == 'canofe' || head.name == 'precio' || head.name == 'descuento' || head.name == 'impnet' || head.name == 'imptot' || head.name == 'gestion'" class="text-rigth">{{head.label}}</th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-element let-columns="columns" let-i="rowIndex">
            <tr [ngClass]="{'row-color':i %2 == 0}">
              <ng-container *ngFor="let head of columns">
                <!--<td class="text-center" *ngIf="head.name == 'fecha'"> {{element[head.name]| date:'dd-MM-yyyy'}} </td>-->
                <td class="text-rigth" *ngIf="head.name == 'fecent'">
                  <p-calendar (onSelect)="updateCesta(element)" (change)="updateCesta(element)" [minDate]="minDate" dateFormat="dd/mm/yy" [(ngModel)]="element[head.name]" [showIcon]="true" inputId="icon"></p-calendar>

                </td>
                <td class="text-rigth" *ngIf="(head.name == 'impnet') && element.precio > 0"><strong>{{element[head.name]| currency:'EUR':'symbol':'1.3-3'}}</strong> </td>
                <td class="text-rigth" *ngIf="(head.name == 'imptot') && element.precio > 0"> {{element[head.name]| currency:'EUR':'symbol':'1.3-3'}} </td>
                <td class="text-rigth" *ngIf="head.name == 'precio' && element.precio > 0"> {{element[head.name]| currency:'EUR':'symbol':'1.3-3'}} </td>

                <td class="text-left" *ngIf="head.name == 'precio' && element.precio == 0"><strong> A CONSULTAR </strong></td>

                <td class="text-left" *ngIf="head.name == 'codart' || head.name == 'nomart'"> {{element[head.name]}} </td>
                <td class="text-rigth" *ngIf="head.name == 'descuento' && element.precio > 0"> {{element[head.name]}} </td>
                <td class="text-left" *ngIf="(head.name == 'descuento' || head.name == 'imptot' || head.name == 'impnet' ) && element.precio == 0">  </td>
                <td class="text-rigth" *ngIf="head.name == 'canofe'">
                  <!--<p-inputNumber (onBlur)="updateCesta(element)" class="text-left" [(ngModel)]="element[head.name]" mode="decimal" [min]="1"></p-inputNumber>-->
                  <input type="number" pInputText [(ngModel)]="element[head.name]" [min]="0" (change)="updateCesta(element)" tabindex="1">

                </td>

                <td class="text-rigth" *ngIf="head.name == 'gestion'">
                  <p-button label="Eliminar" class="p-button-primary" icon="pi pi-trash" iconPos="right" [loading]="isLoading" (onClick)="deleteArticuloCesta(element)"></p-button>
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </p-panel>
    </div>

    <div class="col-3 mt-4 h-40rem">
      <div *ngIf="direccionesList" class="card text-left text-white h-18rem">
        <div class="card-header">Orden de Pedido</div>
        <div *ngFor="let dire of direccionesListF" class="card-body text-dark w-100 text-justify">
          <div><span>Cliente: </span>{{dire.empres}}</div>
          <div><span>Dirección: </span>{{dire.direcc}}</div>
          <div><span>Ciudad: </span>{{dire.poblac}}</div>
          <div><span>Población: </span>{{dire.nomprv}}</div>
          <div><span>CP: </span>{{dire.codpos}}</div>
          <div class="mt-4"><span>Fecha: </span>{{current|date:'dd/MM/YYYY'}}</div>

        </div>
      </div>

      <div *ngIf="articulosCesta" class="card text-left text-white mt-4 h-15rem">
        <div class="card-header">Resumen pedido</div>
        <div *ngIf="visible" class="card-body text-dark w-100 text-justify">
          <div class="grid col-11 relative">
            <span>Importe pedido: </span><span class="absolute right-0">{{imptot | currency:'EUR':'symbol':'1.3-3'}}</span>
          </div>
          <div class="grid col-11 relative" *ngIf="desb2b != 0">
            <span>Descuento B2B: </span><span class="absolute right-0">{{desb2b | currency:'EUR':'symbol':'1.3-3'}}</span>
          </div>
          <div class="grid col-11 relative">
            <span>Total Neto: </span><span class="absolute right-0">{{totnet | currency:'EUR':'symbol':'1.3-3'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-8 mt-4 h-40rem">
      <div *ngIf="direccionesList" class="card text-left text-white">
        <div class="card-header">Envío</div>
        <div class="card-body text-dark w-100 text-justify">
          <div class="field">
            <h5>Nº pedido cliente: </h5>
            <textarea [(ngModel)]="refter" (ngModelChange)="changeRefter($event)" class="inputfield w-full" rows="1" pInputTextarea></textarea>
            <h5 *ngIf="!refter" id="username2-help" class="p-error block">Nº pedido Obligatorio.</h5>
          </div>
          <div class="grid">
            <div class="col-6">
              <h5>Dirección de envío:</h5>
              <p-dropdown [options]="direccionesListEnv" autoWidth="false" [style]="{'width':'100%'}" [(ngModel)]="selectedDir" optionLabel="direcc"></p-dropdown>
            </div>
            <div class="col-6" >
              <h5 *ngIf="portes == 'D'">Tipo de Transporte : Debidos</h5>
              <h5 *ngIf="portes == 'P'">Tipo de Transporte : Pagados</h5>
              <p-dropdown [options]="tipotransporte" [disabled]="portes == 'P'" autoWidth="false" [style]="{'width':'100%'}" [(ngModel)]="selectedTipotransporte" optionLabel="label"></p-dropdown>
            </div>
            <!--
            <div class="col-6" *ngIf="portes == 'P'">
              <h5>Tipo de Transporte : Pagados</h5>
            </div>-->
            <div class="col-offset-6 col-3" *ngIf="portes == 'D' && user.limite - totnet > 0 && user.limite > 0">
              <h5>Límite Portes Pagados</h5>
              <textarea [ngModel]="user.limite | currency:'EUR':'symbol':'1.2-2'" (ngModelChange)="user.limite=$event" [disabled]="true" class="inputfield" rows="1" pInputTextarea></textarea>
            </div>
            <div class="col-3" *ngIf="portes == 'D' && user.limite - totnet > 0 && user.limite > 0">
              <h5>Pendiente Portes Pagados</h5>
              <textarea [ngModel]="user.limite - totnet | currency:'EUR':'symbol':'1.2-2'" (ngModelChange)="user.limite - totnet==$event" [disabled]="true" class="inputfield" rows="1" pInputTextarea></textarea>
            </div>
          </div>
          <div class="field">
            <h5>Observaciones:  </h5>
            <textarea [(ngModel)]="coment" (ngModelChange)="changeComment($event)" class="inputfield w-full" rows="5" pInputTextarea></textarea>
          </div>

          <div *ngIf="totnet < user.minped">
            <p-messages severity="error">
              <ng-template pTemplate>
                <div class="ml-2"><b>{{msgs1}}</b></div>
              </ng-template>
            </p-messages>
          </div>
          <div class="field">
            <p-button label="Enviar Pedido" class="p-button-primary" [loading]="isLoading"  [disabled]="!(refter!=null && selectedDir != null && imptot > user.minped)" (onClick)="enviarPedido()"></p-button>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
  <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
