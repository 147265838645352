import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { catchError, empty } from 'rxjs';
import { DataService } from '../../core/services/data.service';
import { B2BArticulosService } from '../services/b2bArticulos.service';
@Injectable()
export class ArticulosResolver implements Resolve<any> {
  constructor(private data: DataService, public articulosService: B2BArticulosService) {

  }
  resolve(route: ActivatedRouteSnapshot) {

    let a = this.data.currentData;
    return this.articulosService.GetArticulos(this.data.currentData.codcli,
      this.data.currentData.sqlcond,
      this.data.currentData.alto,
      this.data.currentData.ancho,
      this.data.currentData.largo,
      this.data.currentData.codcat, this.data.currentData.codfam).pipe(
        catchError((error) => {
          return empty();
        })
      );

   }
   
}
