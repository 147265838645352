<p-toast key="toast"></p-toast>
<p-messages key="message"></p-messages>


<div class="container-fluid w-100 ml-5">
  <div class="grid mt-4">
    <div class="col-4 col-offset-1">
      <div class="card text-left text-white h-20rem">
        <div class="card-header">Descarga códigos artículos</div>
        <div class="card-body">
          <p class="card-text text-dark text-left text-2xl">
            Descargue en EXCEL los códigos de los artículos de Euro Bearings Spain para poder vincularlos a sus productos / pedidos.
          </p>
          <button pButton type="button" label="Descargar" class="p-button-primary mr-2" [loading]="isLoading" (click)="descargarDocumento('xls')"></button>

        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card text-left text-white h-20rem">
        <div class="card-header">Carga del pedido al B2B</div>
        <div class="card-body">
          <p class="card-text text-dark text-left text-2xl">
            Suba el CSV,XLS o XLSX de su pedido de forma automática. Recuerde que los códigos de producto deben corresponderse con los de Euro Bearings Spain.
          </p>
          <button pButton pRipple label="CARGAR" class="p-button-primary p-mr-2" (click)="openNew()"></button>

        </div>
      </div>
    </div>
  </div>
</div>
<p-dialog [(visible)]="pedidoDialog" [style]="{width: '750px'}" header="Carga Pedido" [modal]="true" styleClass="p-fluid">
  <div class="progress-spinner" *ngIf="isLoadingFile">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <ng-template pTemplate="content">
    <div class="field" *ngIf="!isLoadingFile">

      <div class="formgrid grid">
        <div class="col-12">
          <p-fileUpload name="demo[]" customUpload="true" (uploadHandler)="myUploader($event)" (onUpload)="onUpload($event)"
                        multiple="multiple" accept=".csv,.xls,.xlsx" maxFileSize="100000000">
            <ng-template pTemplate="content">
              <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
              </ul>
            </ng-template>
          </p-fileUpload>
        </div>
      </div>
    </div>
  </ng-template>


  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cerrar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>



